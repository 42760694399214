import { createRouter, createWebHistory } from 'vue-router'  
 
import Overtimeorder from '../views/Overtimeorder.vue'  
import Longtailorder from '../views/Longtailorder.vue'
import Earlywarnorder from '../views/Earlywarnorder.vue'
import Oneselfbuildorder from '../views/Oneselfbuildorder.vue'
import Orderlist from '../views/Orderlist.vue'
import OrderPOList from '../views/OrderPOList.vue'
import Detail from '../views/Detail.vue'
import ChatPage from '../views/chatPage.vue'
import ComputerRoom from '../views/ComputerRoom.vue'
import Whitepage from '../views/whitepage.vue'
import OperaDashboard from '../views/operaDashboard.vue'
import TPCDashboard from '../views/TPCDashboard.vue'
import poDetail from '../views/poDetail.vue'
import WorkManagement from '@/views/staffManagement/WorkManagement.vue'
import SchedulingManagement from '@/views/staffManagement/SchedulingManagement.vue'
import TravelManagement from '@/views/staffManagement/TravelManagement.vue'
import PersonTag from '@/views/staffManagement/PersonTag.vue'
import Training from '@/views/staffManagement/Training.vue'
import Examination from '@/views/staffManagement/Examination.vue'
import Performance from '@/views/staffManagement/Performance.vue'
import NetWorkorder from '@/views/NetWorkorder.vue'
import Serviceorder from '@/views/Serviceorder.vue'
import NetWorkDetail from '@/views/NetworkDetail.vue'
import ServiceDetail from '@/views/ServiceDetail.vue'
import PODashboard from '@/views/PODashboard.vue'
import UserRoom from '@/views/UserRoom.vue'
import SelfBuildOrder from '@/views/SelfBuildOrder.vue'
import OrderLists from '@/views/OrderLists.vue'
import SelfBuildOrderDetail from '@/views/SelfBuildOrderDetail.vue'
import CountList from '@/views/CountList.vue'
import CountListDetail from '@/views/CountListDetail.vue'
import AssetCountList from '@/views/AssetCountList'
import AssetCountListDetail from '@/views/AssetCountListDetail'

const routes = [  
  {  
    path: '/Overtimeorder',  
    name: 'overtimeorder',  
    component: Overtimeorder  
  },  
  {  
    path: '/Longtailorder',  
    name: 'longtailorder',  
    component: Longtailorder  
  }, 
  {  
    path: '/Earlywarnorder',  
    name: 'earlywarnorder',  
    component: Earlywarnorder  
  }, 
  {  
    path: '/Oneselfbuildorder',  
    name: 'oneselfbuildorder',  
    component: Oneselfbuildorder  
  }, 
  {  
    path: '/Orderlist',  
    name: 'orderlist',  
    component: Orderlist  
  },
  {  
    path: '/OrderLists',  
    name: 'orderLists',  
    component: OrderLists  
  },
  {
    path:'/NetWorkorder',
    name: 'NetWorkorder',  
    component: NetWorkorder  
  },
  {  
    path: '/NetWorkDetail/:id', // 使用动态路由参数  
    name: 'NetWorkDetail',  
    component: NetWorkDetail  
  },
  {  
    path: '/Serviceorder',  
    name: 'Serviceorder',  
    component: Serviceorder
  },
  {  
    path: '/ServiceDetail/:id', // 使用动态路由参数  
    name: 'ServiceDetail',  
    component: ServiceDetail  
  },
  {  
    path: '/OrderPOList',  
    name: 'OrderPOList',  
    component: OrderPOList  
  },
  {  
    path: '/ChatPage',  
    name: 'chatPage',  
    component: ChatPage  
  },
  {  
    path: '/OperaDashboard',  
    name: 'operaDashboard',  
    component: OperaDashboard  
  }, 
  {  
    path: '/TPCDashboard',  
    name: 'TPCDashboard',  
    component: TPCDashboard  
  },
  {  
    path: '/ComputerRoom',  
    name: 'computerRoom',  
    component: ComputerRoom  
  },
  {  
    path: '/detail/:id', // 使用动态路由参数  
    name: 'Detail',  
    component: Detail  
  },
  {  
    path: '/podetail/:id', // 使用动态路由参数  
    name: 'poDetail',  
    component: poDetail  
  },
  {  
    path: '/Whitepage',  
    name: 'whitepage',  
    component: Whitepage
  }, 
  {
    path:'/WorkManagement',
    name:'WorkManagement',
    component: WorkManagement
  },
  // {
  //   path:'/WorkManagement/:id',
  //   name:'WorkManagementDetail',
  //   component: WorkManagementDetail
  // },
  {
    path:'/SchedulingManagement',
    name:'SchedulingManagement',  
    component: SchedulingManagement
  },
  // {
  //   path:'/SchedulingManagement/:id',
  //   name:'SchedulingManagementDetail',
  //   component: SchedulingManagementDetail
  // },
  {
    path:'/TravelManagement',
    name:'TravelManagement',  
    component: TravelManagement
  },
  // {
  //   path:'/TravelManagement/:id',
  //   name:'TravelManagementDetail',
  //   component: TravelManagementDetail
  // },
  {
    path:'/PersonTag',
    name:'PersonTag',  
    component: PersonTag
  },
  // {
  //   path:'/PersonTag/:id',
  //   name:'PersonTagDetail',
  //   component: PersonTagDetail
  // },
  {
    path:'/Training',
    name:'Training',  
    component: Training
  },
  // {
  //   path:'/Training/:id',
  //   name:'TrainingDetail',
  //   component: TrainingDetail
  // },
 
  {
    path:'/Examination',
    name:'Examination',  
    component: Examination
  },
  // {
  //   path:'/Examination/:id',
  //   name:'ExaminationDetail',
  //   component: ExaminationDetail
  // },
  {
    path: '/Performance',  
    name: 'Performance',  
    component: Performance
  },
  // {
  //   path: '/Performance/:id',
  //   name: 'PerformanceDetail',
  //   component: PerformanceDetail
  // },
  {
    path: '/PODashboard',  
    name: 'PODashboard',  
    component: PODashboard
  },
  {
    path: '/UserRoom',  
    name: 'UserRoom',  
    component: UserRoom
  },
  {
    path: '/SelfBuildOrder',  
    name: 'SelfBuildOrder',  
    component: SelfBuildOrder  
  },
  {
    path: '/SelfBuildOrderDetail/:id',  
    name: 'SelfBuildOrderDetail',  
    component: SelfBuildOrderDetail
  },
  {
    path: '/CountList',
    name: 'CountList',
    component: CountList
  },
  {
    path: '/CountListDetail/:id',
    name: 'CountListDetail',
    component: CountListDetail
  },
  {
    path: '/AssetCountList',
    name: 'AssetCountList',
    component: AssetCountList
  },
  {
    path: '/AssetCountListDetail/:id',
    name: 'AssetCountListDetail',
    component: AssetCountListDetail
  }
]  
  
const router = createRouter({  
  history: createWebHistory(), 
  routes 
})  
router.beforeEach((to, from, next) => {  
  if (to.path) {  
    localStorage.setItem('activeIndex', to.path);  
    next(); 
  }
});  
export default router
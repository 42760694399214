import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

export function getHeaderRow(columns){
    return columns.map(column => column.label);  
}

export function exportToExcel(tableName, rows, columns){
    let datas = []
    let header = getHeaderRow(columns)
    if (rows.length > 0) {
        for (let row of rows) {
            let data1 = []
            columns.forEach(field => {
                data1.push(row[field.prop])
            })

            datas.push(data1)
        }
    }
    const ws = XLSX.utils.aoa_to_sheet([header, ...datas]); // 将数据转换为工作表  
    const wb = XLSX.utils.book_new(); // 创建一个新的工作簿  
    XLSX.utils.book_append_sheet(wb, ws, tableName); // 将工作表添加到工作簿  
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' }); // 将工作簿转换为 Excel 文件（二进制数组）  
    try {
        saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${tableName}.xlsx`); // 保存文件  
    } catch (e) {
        if (typeof console !== 'undefined') console.log(e, wbout);
    }
    return wbout;
}

export function objectToQueryString(obj) {  
    if (!obj) return '';  
    var parts = [];  
    for (var key in obj) {  
        if (obj.hasOwnProperty(key)) {
            if (obj[key] instanceof Array) {
                obj[key].forEach(val => parts.push(encodeURIComponent(key) + '=' + encodeURIComponent(val)));
            } else {
                var encodedKey = encodeURIComponent(key);  
                var encodedValue = encodeURIComponent(obj[key]);  
                parts.push(encodedKey + '=' + encodedValue);  
            }
        }  
    }  
  
    return parts.join('&');  
}  
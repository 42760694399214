<template>
    <div v-loading="loading">
        <div class="po-detail">
            <h2 disabled mode="h2" class="po-title">计费单列表详情</h2>
            <div class="po-content">
                <el-form :inline="true" :model="poDetail" class="form-inline form-first margin-bt" label-position="left"
                    label-width="150px">
                    <el-form-item label="单号">{{ poDetail.gdh }}</el-form-item>
                    <el-form-item label="单据状态">{{ poDetail.djzt }}</el-form-item>
                    <el-form-item label="工单标题">{{ poDetail.gdbt }}</el-form-item>
                    <el-form-item label="工单类型">{{ poDetail.gdlx }}</el-form-item>
                    <el-form-item label="派单时间">{{ poDetail.pdsj }}</el-form-item>
                    <el-form-item label="sla截止时间">{{ poDetail.slajzsj }}</el-form-item>
                    <el-form-item label="按件计算的数量">{{ poDetail.ajjsdsl }}</el-form-item>
                    <el-form-item label="按量计算的数量">{{ poDetail.aljsdsl }}</el-form-item>
                    <el-form-item label="流程标识">{{ poDetail.lcbs }}</el-form-item>
                </el-form>
            </div>
        </div>
        <div class="po-detail">
            <h2 disabled mode="h2" class="po-title">结单信息</h2>
            <div class="po-content">
                <el-form :inline="true" :model="poDetail" class="form-inline">
                    <el-row :gutter="20">
                        <el-col :xs="24" :sm="24" :md="24">
                            <el-form-item label="处理人的kejiaccount科技账号">{{ poDetail.kjzh }}</el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="24">
                            <el-form-item label="手机号">{{ poDetail.tel }}</el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useRouter, useRoute } from 'vue-router'
import { ref, onMounted } from 'vue'
import { get, post } from '../components/https'

const router = useRouter()
const route = useRoute()
const poDetail = ref({})
const gdh = ref('')
// 加载状态
const loading = ref(false)

onMounted(() => {
    gdh.value = route.params.id
    getDataDetail(gdh.value)
})

const getDataDetail = (param) => {
    loading.value = true
    get('poAssets/getDetail?gdh=' + param).then(data => {
        if (data.errorCode == 0) {
            poDetail.value = data.result
        }
    }).finally(()=>{loading.value = false})
}
</script>
<style scoped>
.po-detail {
    margin: 0 20px;
}

.po-title {
    padding: 0;
    margin: 20px 0;
    box-sizing: border-box;
    white-space: pre-wrap;
    border-bottom-color: rgb(74, 144, 226);
    border-style: solid;
    text-align: center;
    width: auto;

}

.form-first .el-form-item {
    width: 46%;
    margin-bottom: 25px;
}

/deep/.form-inline .el-form-item__label {
    color: #606266;
    font-weight: 700;
}

.margin-bt {
    margin-bottom: 22px;
}

.margin-bt-double {
    margin-bottom: 40px;
}
.table-second, .table-four {
    border-bottom-color: rgb(74, 144, 226);
    border-bottom-style: solid;
}

.form-item-inline {
    display: inline-flex;
    width: 48%;
}
</style>
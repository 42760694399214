<template>
    <div class="work-management">
        <div class="content-header">
            <div class="span-style" />
            <span>绩效管理</span>
        </div>
        <div class="box-card">
            <el-row :gutter="20">
                <el-col :span="6">
                    <el-card class="statis-card">
                        <el-statistic title="考核人数" :value="khrs" class="statis-div" />
                    </el-card>
                </el-col>
                <el-col :span="6">
                    <el-card class="statis-card">
                        <el-statistic title="满分人数" :value="mfrs" class="statis-div" />
                    </el-card>
                </el-col>
                <el-col :span="6">
                    <el-card class="statis-card">
                        <el-statistic title="合格人数" :value="hgrs" class="statis-div" />
                    </el-card>
                </el-col>
                <el-col :span="6">
                    <el-card class="statis-card">
                        <el-statistic title="不合格人数" :value="bhgrs" class="statis-div" />
                    </el-card>
                </el-col>
            </el-row>
        </div>
        <div class="table-div">
            <div class="content-header">
                <div class="span-style" />
                <span>搜索条件</span>
            </div>
            <el-card class="box-card">
                <el-form :inline="true" :model="formInline" class="form-inline">
                    <el-form-item label="关键字">
                        <el-input v-model="formInline.gjz"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="机房">
                        <el-input v-model="formInline.room"></el-input>
                    </el-form-item>
                    <el-form-item label="申请日期">
                        <el-date-picker
                            v-model="formInline.sqrq"
                            type="date"
                        />
                    </el-form-item> -->
                    <el-row class="margin-style"><el-col>
                            <el-button @click="onRest">重置</el-button>
                            <el-button type="primary" @click="getTicketList(currentPage)">搜索</el-button>
                        </el-col></el-row>
                </el-form>
            </el-card>

            <div class="content-header">
                <div class="span-style" />
                <span>工单列表</span>
            </div>
            <el-button @click="exportToExcels('绩效考核', tableData)">导出</el-button>
            <el-upload class="upload-excel" action="#" :show-file-list="false" :on-change="handleExcelChange"
                accept=".xlsx,.xls">
                <el-button type="primary">绩效导入</el-button>
            </el-upload>
            <!-- <el-button @click="importToExcels(tableData)">绩效导入</el-button> -->
            <el-table ref="multipleTableRef" :data="tableData" @selection-change="handleSelectionChange" row-key="id"
                @row-dblclick="handleRowDblclick">
                <el-table-column type="selection" width="30" />
                <el-table-column prop="name" label="考核名称" sortable>
                </el-table-column>
                <el-table-column prop="scheme" label="考核方案" sortable>
                </el-table-column>
                <el-table-column prop="nf" label="考核年份" sortable>
                </el-table-column>
                <el-table-column prop="month" label="考核月份" sortable>
                </el-table-column>
                <el-table-column prop="jxfs" label="绩效分数" sortable>
                </el-table-column>
                <el-table-column prop="duixiang" label="考核对象" sortable>
                </el-table-column>
                <el-table-column prop="room" label="一级机房" sortable>
                </el-table-column>
                <el-table-column prop="result" label="考核结果" sortable>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="120">
                    <template #default="scope">
                        <el-button link type="danger" size="small" @click.prevent="delclick(scope.row)" v-show="userId === '胡颖纯'">
                            删除
                        </el-button>
                        <el-button link type="primary" size="small" @click.prevent="handleRowDblclick(scope.row)">
                            明细
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange"
                :current-page="currentPage" :page-sizes="[20, 50, 100]" :page-size="pageSize"
                layout="total, sizes,prev, pager, next, jumper" :total="totalData"></el-pagination>
        </div>
    </div>
</template>

<script setup>
// 使用 require 方式导入 XLSX 库
const XLSX = require('xlsx');
import { reactive, ref, onMounted, computed } from 'vue'
import { exportToExcel, objectToQueryString } from '../../components/exportUtils.js';
import { get, post } from '../../components/https';
import { useRouter } from 'vue-router';

const router = useRouter();

const formInline = ref({
    gjz: '',
    room: '',
})
const tableData = ref([])
const hgrs = ref(0),bhgrs = ref(0),mfrs = ref(0),khrs = ref(0)
onMounted(() => {
    getTicketList(currentPage.value)
    getLastMonth()
})
const getLastMonth = () => {
    get('performance/lastMonth', {}, {}).then(data => {
        if (data.errorCode === 0) {
            hgrs.value = data.result.hgrs
            bhgrs.value = data.result.bhgrs
            mfrs.value = data.result.mfrs
            khrs.value = data.result.khrs
        }
    })
}
const onRest = () => {
    formInline.value = {
        pgjz: ''
    }
}
const multipleTableRef = ref()
const multipleSelection = ref([])
const handleSelectionChange = (val) => {
    multipleSelection.value = val
}
// 分页参数  
const currentPage = ref(1);
const pageSize = ref(20);
const totalData = ref(0)
const userId = localStorage.getItem('UserId');

// 当前页码改变时的处理  
function handleCurrentChange(val) {
    currentPage.value = val;
    getTicketList(currentPage.value)
}
// 当分页数据改变时
function handleSizeChange(val) {
    pageSize.value = val;
    getTicketList(currentPage.value)
}
const getTicketList = (currentPage) => {
    let queryString = objectToQueryString(formInline.value);
    get('performance/list?currentPage=' + currentPage + '&' + 'pageSize=' + pageSize.value + '&' + queryString).then(data => {
        if (data.errorCode === 0) {
            tableData.value = data.result.content
            totalData.value = data.result.totalElements
            tableData.value.forEach(item => {
                item.showFullContent = false;
            });
        }

    })
}
// 导出表格数据
const exportToExcels = (tableName, rows) => {
    let queryString = objectToQueryString(formInline.value);
    const url = 'https://www.aiyuservice.com/api/performance/export?' + queryString
    fetch(url, { method: 'GET', headers: { 'Satoken': localStorage.getItem('Satoken') } })
      .then(response => {
            return response.blob();
        }).then(blob => {
            const blobUrl = window.URL.createObjectURL(blob); 
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', tableName + '.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        }).catch(error => {
            console.error('下载失败：', error);
        });
}
const handleExcelChange = (file) => {
    if (file && file.raw) {
        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                
                const formData = new FormData();
                formData.append('file', file.raw); // 将文件添加到 FormData 中
                post('performance/import', formData).then(response => {
                    if (response.errorCode === 0) {
                        // 上传成功，更新表格数据
                        getTicketList(currentPage.value)
                    } else {
                        console.error('上传失败：', response.errorMessage);
                    }
                }).catch(error => {
                    console.error('网络错误：', error);
                });
            } catch (error) {
                console.error('处理 Excel 数据时出错:', error);
            }
        };
        reader.readAsArrayBuffer(file.raw);
    } else {
        console.error('文件不存在或文件数据不完整');
    }
}
const handleRowDblclick = (row) => {
    router.push({ name: 'Detail', params: { id: row.gdh } });
}

const delclick = (row) => {
    get('performance/del?id='+ row.id).then(data => {
        if (data.errorCode === 0) {
            getTicketList(currentPage.value)
        }
    })
}
</script>

<style scoped>
.el-col {
    margin-bottom: 20px;
}

.statis-card {
    background: #F5F9FF;
}

.upload-excel {
    display: inline-block;
    margin-left: 10px;
}
</style>
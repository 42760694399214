<template>
    <div v-loading="loading">
        <!-- 全局遮罩 -->
        <div class="content-header">
            <div class="span-style" />
            <span>搜索条件</span>
        </div>
        <el-card class="box-card">
            <el-form :inline="true" :model="formInline" class="form-inline">
                <!-- <el-form-item label="关键字">
                    <el-input v-model="formInline.gjz" clearable></el-input>
                </el-form-item> -->
                <el-form-item label="单号">
                    <el-input v-model="formInline.ticket_id" clearable></el-input>
                </el-form-item>
                <el-form-item label="腾讯工单号">
                    <el-input v-model="formInline.txOrder" clearable></el-input>
                </el-form-item>
                <el-form-item label="专项名称">
                    <el-select v-model="formInline.svrItem" placeholder="专项名称" clearable multiple>
                        <el-option v-for="elOption in zxmcOptions" :key="elOption" :label="elOption"
                            :value="elOption"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="一级机房">
                    <el-select v-model="formInline.idcpName" placeholder="一级机房" clearable>
                        <el-option v-for="elOption in jfOptions" :key="elOption" :label="elOption"
                            :value="elOption"></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="工单状态">
                    <el-select v-model="formInline.status" clearable>
                        <el-option value="运行中" label="运行中"></el-option>
                        <el-option value="已结单" label="已结单"></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="创建时间" clearable>
                    <el-date-picker v-model="formInline.creatTime" type="datetimerange" range-separator="—"
                        start-placeholder="开始时间" end-placeholder="结束时间" value-format="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss"/>
                </el-form-item>
                <el-form-item label="PO订单号">
                    <el-input v-model="formInline.orderNo" clearable></el-input>
                </el-form-item>
                <el-row class="margin-style"><el-col>
                        <el-button @click="onRest">重置</el-button>
                        <el-button type="primary" @click="onSubmit">批量催单</el-button>
                        <el-button type="primary" @click="getTicketList(currentPage)">搜索</el-button>
                    </el-col></el-row>
            </el-form>
        </el-card>

        <div class="content-header">
            <div class="span-style" />
            <span>工单列表</span>
        </div>
        <el-button @click="exportToExcels('计费单列表', tableData)">导出</el-button>
        <el-table ref="multipleTableRef" :data="tableData" @selection-change="handleSelectionChange" row-key="id"
            @row-dblclick="handleRowDblclick">
            <el-table-column type="selection" width="30" />
            <el-table-column prop="ticket_id" label="单号" sortable fixed>
            </el-table-column>
            <el-table-column v-for="(column, index) in columns" :key="index" :prop="column.prop" :label="column.label"
                sortable />
            <el-table-column fixed="right" label="操作" width="120">
                <template #default="scope">
                    <el-button link type="primary" size="small" @click.prevent="handleRowDblclick(scope.row)">
                        明细
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="currentPage"
            :page-sizes="[20, 50, 100]" :page-size="pageSize" layout="total, sizes,prev, pager, next, jumper"
            :total="totalData"></el-pagination>
    </div>
</template>

<script setup>
import { ArrowDown, ArrowUp } from '@element-plus/icons-vue'
import { reactive, ref, onMounted, computed } from 'vue'
import { exportToExcel, objectToQueryString } from '../components/exportUtils.js';
import { get, post } from '../components/https';
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';

const router = useRouter();
const jfOptions = ['成都电信高新AC', '成都电信西区803DC', '济南腾讯春晖EC', '上海腾讯花桥DC', '昆山腾讯万国DC', '南京电信吉山DC', '南京电信二长DC/南京腾讯江宁DC', '其他联通加速点AC', '上海GDS富美DC', '上海移动金桥DC', '上海移动云桥DC', '上海腾讯松江月湖DC', '上海腾讯腾讯大厦ODC', '上海联通万荣AC', '石家庄腾讯常山EC', '张家口腾讯怀来瑞北DC', '广州中航云南沙DC', '太原移动太原泽信EC', '郑州腾讯高新区EC', '西安移动西咸新区EC', '武汉电信临空港EC', '合肥电信天都EC', '南昌联通丰和南EC', '沈阳腾讯铁西EC', '长沙电信望城EC', '长沙移动云谷EC']
const zxmcOptions = ['拔盘', '下架', '上架', '接线', '服务器退役拔盘', 'GPU服务器线缆连接', 'GPU服务器搬迁上下架', '网络链路调试', '零星链路布放与裁撤', '网络设备下架及部件拆解', '服务器故障诊断-普通', '服务器故障诊断-GPU', '服务器故障维修-简单', '服务器故障维修-普通', '服务器故障维修-复杂', 'IDC现场监管', 'GPU服务器部署排错(TPC)', '服务器搬迁上下架-8U以上', '服务器搬迁上下架-2U-8U(不含2U)', '服务器搬迁上下架-2U及以下', '网络变更现场支持单', '网络诊断及故障', '特殊变更', '网络端口/模块操作', 'ODF链路操作']

// 加载状态
const loading = ref(false);

const exportToExcels = (tableName, rows) => {
    // 显示全局遮罩
    loading.value = true;
    let formData = { ...formInline.value };
    if (Array.isArray(formData.svrItem)) {
        formData.svrItem = formData.svrItem.join(',');
    }
    if (formData.creatTime && formData.creatTime.length > 0) {
        formData.startTime = formData.creatTime[0];
        formData.endTime = formData.creatTime[1];
    } else {
        formData.startTime = '';
        formData.endTime = '';
    }
    delete formData.creatTime; // 删除原始的 creatTime 字段
    let queryString = objectToQueryString(formData);
    const url = 'https://www.aiyuservice.com/api/poconfirm/export?' + queryString
    fetch(url, { method: 'GET', headers: { 'Satoken': localStorage.getItem('Satoken') } })
        .then(response => {
            if (!response.ok) {
                throw new Error(`下载失败，状态码：${response.status}`);
            }
            return response.blob();
        })
        .then(blob => {
            const blobUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', tableName + '.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
            // 关闭全局遮罩
            loading.value = false;
        })
        .catch(error => {
            // 关闭全局遮罩
            loading.value = false;
            // 提示错误信息
            ElMessage.error('下载接口报错：' + error.message);
        });
}

// 分页参数  
const currentPage = ref(1);
const pageSize = ref(20);
const totalData = ref(0)

// 当前页码改变时的处理  
function handleCurrentChange(val) {
    currentPage.value = val;
    getTicketList(currentPage.value)
}
function handleSizeChange(val) {
    pageSize.value = val;
    getTicketList(currentPage.value)
}
const dateArr = ref('')

const formInline = ref({
    gjz: '',
    orderNo: '',
    ticket_id: '',
    txOrder: '',
    idcpName: '',
    svrItem: [],
    status: '',
    creatTime: [],
    startTime: '',
    endTime: ''
})

const columns = [
    {
        prop: 'orderNo',
        label: 'PO订单号'
    },
    // {
    //     prop: 'status',
    //     label: '工单状态'
    // },
    {
        prop: 'svrItem',
        label: '专项名称'
    },
    {
        prop: 'idcpName',
        label: '一级机房'
    },
    {
        prop: 'jdrzh',
        label: '结单人账号'
    },
    {
        prop: 'realNum',
        label: '实际处理数量'
    },
    {
        prop: 'txOrder',
        label: '腾讯工单号'
    },
    {
        prop: 'createTime',
        label: '创建时间'
    },
    {
        prop: 'slaTime',
        label: '超时时间'
    },
    {
        prop: 'billType',
        label: '账单计费类型'
    }
]

const rowDetail = ref()
const svrItemList = ref([])
const multipleTableRef = ref()
const multipleSelection = ref([])

const handleSelectionChange = (val) => {
    multipleSelection.value = val
}

const tableData = ref([])

onMounted(() => {
    getTicketList(currentPage.value)
    getSvrItem()
})

const getSvrItem = () => {
    get('potickets/svrItem').then(data => {
        if (data.errorCode === 0) {
            svrItemList.value = data.result
        }
    })
}

const getTicketList = (currentPage) => {
    let formData = { ...formInline.value };
    if (Array.isArray(formData.svrItem)) {
        formData.svrItem = formData.svrItem.join(',');
    }
    if (formData.creatTime && formData.creatTime.length > 0) {
        formData.startTime = formData.creatTime[0];
        formData.endTime = formData.creatTime[1];
    } else {
        formData.startTime = '';
        formData.endTime = '';
    }
    delete formData.creatTime; // 删除原始的 creatTime 字段
    let queryString = objectToQueryString(formData);
    get('poconfirm/list?currentPage=' + currentPage + '&' + 'pageSize=' + pageSize.value + '&' + queryString).then(data => {
        if (data.errorCode === 0) {
            tableData.value = data.result.content
            totalData.value = data.result.totalElements
            tableData.value.forEach(item => {
                item.showFullContent = false;
            });
        }
    })
}

const dateChange = (value) => {
    formInline.value.startDate = value[0]
    formInline.value.endDate = value[1]
}

const onRest = () => {
    dateArr.value = ""
    formInline.value = {
        gjz: '',
        orderNo: '',
        ticket_id: '',
        txOrder: '',
        idcpName: '',
        svrItem: [],
        status: '',
        creatTime: [],
        startTime: '',
        endTime: ''
    }
}

const handleRowDblclick = (row) => {
    router.push({ name: 'CountListDetail', params: { id: row.id } });
}
</script>   
<template>
    <div class="po-detail">
        <h2 disabled mode="h2" class="po-title">计费单列表详情</h2>
        <div class="po-content">
            <el-form :inline="true" :model="poDetail" class="form-inline form-first margin-bt" label-position="left"
                label-width="150px">
                <el-form-item label="单号">{{ poDetail.ticket_id }}</el-form-item>
                <el-form-item label="PO订单号">{{ poDetail.orderNo }}</el-form-item>
                <el-form-item label="工单状态">{{ poDetail.orderNo }}</el-form-item>
                <el-form-item label="新专项名称">{{ poDetail.newSvrItem }}</el-form-item>
                <el-form-item label="专项名称">{{ poDetail.svrItem }}</el-form-item>
                <el-form-item label="专项是否变更">{{ poDetail.isChange }}</el-form-item>
                <el-form-item label="超时时间">{{ poDetail.slaTime }}</el-form-item>
                <el-form-item label="是否超时">{{ poDetail.timeOut }}</el-form-item>
                <el-form-item label="待处理设备数量">{{ poDetail.dclsbsl }}</el-form-item>
                <el-form-item label="账单计费类型">{{ poDetail.billType }}</el-form-item>
                <el-form-item label="账单类型对应的数量">{{ poDetail.billTypeValue }}</el-form-item>
                <el-form-item label="备注信息">{{ poDetail.billMemo }}</el-form-item>
                <el-form-item label="服务台举证">{{ poDetail.billPhoto }}</el-form-item>
                <el-form-item label="创建时间">{{ poDetail.createTime }}</el-form-item>
                <el-form-item label="服务信息">{{ poDetail.servicelnfo }}</el-form-item>
                <el-form-item label="联系人账号">{{ poDetail.contactAccount }}</el-form-item>
                <el-form-item label="机房管理单元">{{ poDetail.idcName }}</el-form-item>
                <el-form-item label="工单场景">{{ poDetail.scene }}</el-form-item>
                <el-form-item label="一级机房">{{ poDetail.idcpName }}</el-form-item>
                <el-form-item label="实际处理数量">{{ poDetail.realNum }}</el-form-item>
                <el-form-item label="园区">{{ poDetail.campusName }}</el-form-item>
                <el-form-item label="结单时间">{{ poDetail.endTime }}</el-form-item>
                <el-form-item label="订单标题">{{ poDetail.memo }}</el-form-item>
            </el-form>
            <div class="table-second margin-bt">
                <el-table :data="tableData" border style="width: 100%">
                    <el-table-column prop="assetId" label="固资号" />
                    <el-table-column prop="sn" label="SN" />
                    <el-table-column prop="model" label="型号" />
                    <el-table-column prop="rackName" label="机架" />
                    <el-table-column prop="posName" label="机位" />
                    <el-table-column prop="svrType" label="设备类型" />
                </el-table>
            </div>
            <div class="form-three margin-bt-double">
                <el-form :model="poDetail" class="form-inline margin-bt" label-position="left">
                    <el-form-item label="异常原因信息:" prop="supplierMemo" class="form-item-inline">
                        <el-input v-model="poDetail.supplierMemo" disabled type="textarea" :rows="2" placeholder="请输入异常原因信息" />
                    </el-form-item>
                    <el-form-item label="服务商举证:" prop="fwsjz" class="form-item-inline" style="margin-left: 10px;">
                        <el-input v-model="poDetail.fwsjz" disabled ></el-input>
                    </el-form-item>
                    <el-form-item label="确认信息:" prop="checkMemo" class="form-item-inline">
                        <el-input type="text" v-model="poDetail.checkMemo" disabled></el-input>
                    </el-form-item>
                </el-form>
            </div>
            
        </div>
    </div>
</template>
<script setup>
import { useRouter, useRoute } from 'vue-router';
import { ref, onMounted } from 'vue'
import { get, post } from '../components/https';

const router = useRouter();
const route = useRoute();
const poDetail = ref({})
const gdh = ref('')
const tableData = ref([])

onMounted(() => {
    gdh.value = route.params.id
    getDataDetail(gdh.value)
})

const getDataDetail = (param) => {
    get('poconfirm/getInfo?id=' + param).then(data => {
        console.log('拿到数据', data)
        if (data.errorCode == 0) {
            let dataJson = data.result
            poDetail.value = dataJson
            tableData.value = JSON.parse(dataJson.deviceList)
        }
    })
}
</script>
<style scoped>
.po-detail {
    margin: 0 20px;
}

.po-title {
    padding: 0;
    margin: 20px 0;
    box-sizing: border-box;
    white-space: pre-wrap;
    border-bottom-color: rgb(74, 144, 226);
    border-style: solid;
    text-align: center;
    width: auto;

}

.form-first .el-form-item {
    width: 46%;
    margin-bottom: 25px;
}

/deep/.form-inline .el-form-item__label {
    color: #606266;
    font-weight: 700;
}

.margin-bt {
    margin-bottom: 22px;
}

.margin-bt-double {
    margin-bottom: 40px;
}
.table-second, .table-four {
    border-bottom-color: rgb(74, 144, 226);
    border-bottom-style: solid;
}

.form-item-inline {
    display: inline-flex;
    width: 48%;
}
</style>
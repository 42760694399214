import axios from 'axios';
import router from '../router';

// 创建一个 Axios 实例
const service = axios.create({
    baseURL: 'https://www.aiyuservice.com/api/', // 可以在这里设置你的基础 URL
    timeout: 50000, // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
    config => {
        const Satoken = localStorage.getItem('Satoken');
        if (Satoken) {
            config.headers['Satoken'] = Satoken;
        }
        return config;
    },
    error => {
        console.log(error); // for debug
        return Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    response => {
        const res = response.data;
        if (res.errorCode === 6666) {
            router.push("/Whitepage");
            return Promise.reject(new Error('ErrorCode 6666: Redirecting to specified page'));
        }
        return res;
    },
    error => {
        console.log('err' + error); // for debug
        return Promise.reject(error);
    }
);

// 封装 get 请求
export function get(url, params = {}, headers = {}) {
    return service.get(url, {
        params,
        headers: {
            ...service.defaults.headers,
            ...headers
        },
    });
}

// 封装 post 请求
export function post(url, data = {}, headers = {}) {
    return service.post(url, data, {
        headers: {
            ...service.defaults.headers,
            ...headers
        },
    });
}
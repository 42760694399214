<template>
    <div>
        <div class="content-header">
            <div class="span-style" />
            <span>工单详情</span>
            <el-button @click="goBack" :icon="ArrowLeft" type="primary" class="operBtn">返回</el-button>
            <!-- <el-button @click="goEdit" :icon="Edit" class="operBtn" v-if="!editFlag"
                style="margin-right: 10px;">编辑</el-button>
            <el-button @click="goSave" :icon="Edit" class="operBtn" v-if="editFlag"
                style="margin-right: 10px;">保存</el-button> -->
        </div>
        <!-- <h2 class="detail-header">{{ gdh }}详情页</h2> -->
        <!-- 展示详情数据... -->

        <div class="dataDetail">
            <div class="item" v-for="item in transformedArray" :key="item.label">
                <span class="itemLabel">{{ item.label }}：</span>
                <span class="itemValue">{{ item.value }}</span>
            </div>
            <!-- <div class="item" v-if="!editFlag">
                <span class="itemLabel">是否剔除超时：</span>
                <span class="itemValue">{{ editArr.sftccs }}</span>
            </div> -->
            <!-- <div class="item" v-if="editFlag">
                <span class="itemLabel">是否剔除超时：</span>
                <span class="itemValue">
                    <el-radio-group v-model="editArr.sftccs" class="ml-4">
                        <el-radio value="是">是</el-radio>
                        <el-radio value="否">否</el-radio>
                    </el-radio-group>
                </span>
            </div>
            <div class="item" v-if="!editFlag">
                <span class="itemLabel">超时原因：</span>
                <span class="itemValue">{{ editArr.csyy }}</span>
            </div>
            <div class="item" v-if="editFlag">
                <span class="itemLabel">超时原因：</span>
                <span class="itemValue">
                    <el-input v-model="editArr.csyy" maxlength="2000" placeholder="请输入" rows="4"
                        show-word-limit style="margin-bottom: 20px;" type="textarea" />
                </span>
            </div> -->
        </div>
    </div>
</template>

<script setup>
import {
    ArrowLeft
} from '@element-plus/icons-vue'
import { useRouter, useRoute } from 'vue-router';
import { ref, onMounted } from 'vue'
import { get, post } from '../components/https';

const router = useRouter();
const route = useRoute();
const editArr = ref({ gdh: '',sftccs: '', csyy: '' });
const dataLabel = {
    ticket_id: '订单号',
    asset_id: '服务器设备号',
    check_status: '排查状态',
    instance_id: '流程单号',
    order_level: '工单等级',
    order_type: '工单类型',
    task_id: '任务单号',
    upgrade_remark_1: '升级备注1',
    upgrade_remark_2: '升级备注2',
    upgrade_remark_3: '升级备注3',
    urgent_status: '紧急状态',
    chuangjian: '创建时间',
    create_name: '创建人名称'
}
const transformedArray = ref()
const gdh = ref('')
const goBack = () => {
    router.back();
};
const editFlag = ref(false)
const goEdit = () => {
    editFlag.value = true
}
const goSave = () => {
    post('ticket/updatCS', editArr.value).then(data => {
        console.log('拿到数据', data)
        if (data.errorCode == 0) {
            editFlag.value = false
        }
    })
}
onMounted(() => {
    gdh.value = route.params.id
    getDataDetail(gdh.value)
})
const getDataDetail = (param) => {
    get('technology/getInfo?ticket_id=' + param).then(data => {
        console.log('拿到数据', data)
        if (data.errorCode == 0) {
            let dataJson = data.result
            transformedArray.value = transformObjectsToArray(dataLabel, dataJson)
            editArr.value = { gdh: dataJson.gdh,sftccs: dataJson.sftccs, csyy: dataJson.csyy }
        }
    })
}

const transformObjectsToArray = (keysObject, dataObject) => {
    const result = [];

    // 遍历键的集合对象，获取所有的键  
    for (const key in keysObject) {
        // 如果数据对象中也有这个键，并且它的值不是null或undefined  
        if (key in dataObject) {
            // 将键值对转换为{label: key, value: dataObject[key]}格式的对象，并添加到结果数组中  
            result.push({ label: keysObject[key], value: dataObject[key] });
        }
    }

    return result;
}  
</script>
<style scoped>
.detail-header {
    text-align: center;
}

.item {
    height: 45px;
    font-size: 16px;
    display: inline-block;
    width: 32%;
    vertical-align: bottom;
    padding: 5px 5px;
}

.dataDetail {
    padding: 20px;
}

.operBtn {
    float: right;
    font-size: 14px;
}
</style>
<template>
    <div>
        <div class="content-header">
            <div class="span-style" />
            <span>搜索条件</span>
        </div>
        <el-card class="box-card">
            <el-form :inline="true" :model="formInline" class="form-inline">
                <el-form-item label="单号">
                    <el-input v-model="formInline.ticket_id"></el-input>
                </el-form-item>
                <el-form-item label="腾讯工单号">
                    <el-input v-model="formInline.ticketId"></el-input>
                </el-form-item>
                <!-- <el-form-item label="关键字">
                    <el-input v-model="formInline.gjz"></el-input>
                </el-form-item> -->
                <el-form-item label="一级机房">
                    <el-input v-model="formInline.yjjf"></el-input>
                </el-form-item>
                <el-form-item label="专项名称">
                    <el-select v-model="formInline.zx" multiple>
                        <el-option v-for="item in svrItemList" :key="item" :value="item" :label="item"></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="工单状态">
                    <el-select v-model="formInline.status">
                        <el-option value="运行中" label="运行中"></el-option>
                        <el-option value="已结单" label="已结单"></el-option>
                    </el-select>
                </el-form-item> --> 
                <el-form-item label="创建时间" clearable>
                    <el-date-picker v-model="formInline.creatTime" type="datetimerange" range-separator="—" start-placeholder="开始时间" end-placeholder="结束时间" value-format="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss"/>
                </el-form-item>
                <el-form-item label="是否超时">
                    <el-select v-model="formInline.csstatus">
                        <el-option value="是" label="是"></el-option>
                        <el-option value="否" label="否"></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="结单时间" clearable>
                    <el-date-picker v-model="formInline.endTime" type="datetimerange" range-separator="—" start-placeholder="开始时间" end-placeholder="结束时间" value-format="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss"/>
                </el-form-item> -->
                <el-row class="margin-style"><el-col>
                        <el-button @click="onRest">重置</el-button>
                        <el-button type="primary" @click="onSubmit">批量催单</el-button>
                        <el-button type="primary" @click="getTicketList(currentPage)">搜索</el-button>
                    </el-col></el-row>
            </el-form>
        </el-card>

        <div class="content-header">
            <div class="span-style" />
            <span>工单列表</span>
        </div>
        <el-button @click="exportToExcels('工单列表',tableData)">导出</el-button>
        <el-table ref="multipleTableRef" :data="tableData" @selection-change="handleSelectionChange" row-key="id" @row-dblclick="handleRowDblclick">
            <el-table-column type="selection" width="30" />
            <el-table-column prop="ticket_id" label="单号" sortable fixed>
            </el-table-column>
            <el-table-column v-for="(column, index) in columns" :key="index" :prop="column.prop" :label="column.label" sortable/>
            <el-table-column fixed="right" label="操作" width="120">
                <template #default="scope">
                    <el-button link type="primary" size="small" @click.prevent="handleRowDblclick(scope.row)">
                        明细
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="currentPage" :page-sizes="[20,50,100]" :page-size="pageSize" layout="total, sizes,prev, pager, next, jumper" :total="totalData"></el-pagination>
    </div>
</template>

<script setup>
import { ArrowDown, ArrowUp } from '@element-plus/icons-vue'
import { reactive, ref, onMounted, computed } from 'vue'
import { get, post } from '../components/https'
import { useRouter } from 'vue-router'

// 模拟实现 objectToQueryString 函数
const objectToQueryString = (obj) => {
    const parts = []
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key]
            if (Array.isArray(value)) {
                if (key === 'zx') {
                    parts.push(`${key}=${value.join(',')}`)
                } else if (key === 'creatTime' && value.length === 2) {
                    parts.push(`cjstart=${encodeURIComponent(value[0])}`)
                    parts.push(`cjend=${encodeURIComponent(value[1])}`)
                } else if (key === 'endTime' && value.length === 2) {
                    parts.push(`jdstart=${encodeURIComponent(value[0])}`)
                    parts.push(`jdend=${encodeURIComponent(value[1])}`)
                }
            } else {
                parts.push(`${key}=${encodeURIComponent(value)}`)
            }
        }
    }
    return parts.join('&')
}

const router = useRouter()
const exportToExcels = (tableName, rows) => {
    let queryString = objectToQueryString(formInline.value)
    const url = 'https://www.aiyuservice.com/api/potickets/export?' + queryString
    fetch(url, { method: 'GET', headers: { 'Satoken': localStorage.getItem('Satoken') } })
      .then(response => {
            return response.blob()
        })
      .then(blob => {
            const blobUrl = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = blobUrl
            link.setAttribute('download', tableName + '.xlsx')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            window.URL.revokeObjectURL(blobUrl)
        })
      .catch(error => {
            console.error('下载失败：', error)
        })
}

// 分页参数
const currentPage = ref(1)
const pageSize = ref(20)
const totalData = ref(0)

// 当前页码改变时的处理
function handleCurrentChange(val) {
    currentPage.value = val
    getTicketList(currentPage.value)
}
function handleSizeChange(val) {
    pageSize.value = val
    getTicketList(currentPage.value)
}
const dateArr = ref('')
const formInline = ref({
    ticket_id: '',
    ticketId: '',
    yjjf: '',
    zx: [],
    status: '',
    csstatus: '',
    creatTime: [],
    // endTime: []
})
const columns = [
    // {
    //     prop:'status',
    //     label: '工单状态'
    // },
    {
        prop:'svrItem',
        label: '专项名称'
    },
    {
        prop: 'idcpName',
        label: '一级机房'
    },
    {
        prop: 'ticketId',
        label: '腾讯工单号'
    },
    {
        prop: 'createTime',
        label: '创建时间'
    },
    {
        prop: 'chuangjian',
        label: '结单时间'
    },
    {
        prop:'slaTime',
        label: '超时时间'
    },
    {
        prop: 'csstatus',
        label: '是否超时'
    }
]
const rowDetail = ref()
const svrItemList = ref([])
const multipleTableRef = ref()
const multipleSelection = ref([])
const handleSelectionChange = (val) => {
    multipleSelection.value = val
}

const tableData = ref([])
onMounted(() => {
    getTicketList(currentPage.value)
    getSvrItem()
})
const getSvrItem = () => {
    get('potickets/svrItem').then(data => {
        if (data.errorCode === 0) {
            svrItemList.value = data.result
        }
    })
}
const getTicketList = (currentPage) => {
    let queryString = objectToQueryString(formInline.value)
    console.log('get satoken', localStorage.getItem('Satoken'))
    const headers = {
        Satoken: localStorage.getItem('Satoken')
    };
    get('potickets/list?currentPage=' + currentPage + '&' + 'pageSize=' + pageSize.value + '&' + queryString, {}, headers)
      .then(data => {
            if (data.errorCode === 0) {
                tableData.value = data.result.content
                totalData.value = data.result.totalElements
                tableData.value.forEach(item => {
                    item.showFullContent = false
                })
            }
        })
}
const dateChange = (value) => {
    formInline.value.startDate = value[0]
    formInline.value.endDate = value[1]
}
const onRest = () => {
    dateArr.value = ''
    formInline.value = {
        ticket_id: '',
        ticketId: '',
        yjjf: '',
        zx: [],
        status: '',
        csstatus: '',
        creatTime: [],
        // endTime: []
    }
}
const handleRowDblclick = (row) => {
    router.push({ name: 'poDetail', params: { id: row.id } })
}
</script>
<template>
    <div v-loading="loading">
        <div class="content-header">
            <div class="span-style" />
            <span>搜索条件</span>
        </div>
        <el-card class="box-card">
            <el-form :inline="true" :model="formInline" class="form-inline">
                <el-form-item label="关键字">
                    <el-input v-model="formInline.gjz"></el-input>
                </el-form-item>
                <el-form-item label="工单号">
                    <el-input v-model="formInline.gdh"></el-input>
                </el-form-item>
                <el-form-item label="园区">
                    <el-select v-model="formInline.yq" filterable allow-create default-first-option placeholder="请选择">
                        <el-option label="昆山-花桥" value="昆山-花桥" />
                        <el-option label="南京-吉山" value="南京-吉山" />
                    </el-select>
                </el-form-item>
                <el-form-item label="工单类型">
                    <el-select v-model="formInline.gdlx" filterable allow-create default-first-option placeholder="请选择">
                        <el-option label="RMA好件入库" value="RMA好件入库" />
                        <el-option label="位置确认" value="位置确认" />
                        <el-option label="坏件入库" value="坏件入库" />
                        <el-option label="坏件返厂出库" value="坏件返厂出库" />
                        <el-option label="搬迁入库" value="搬迁入库" />
                        <el-option label="搬迁出库" value="搬迁出库" />
                        <el-option label="直配确认" value="直配确认" />
                        <el-option label="维修备件出库" value="维修备件出库" />
                        <el-option label="调拨入库" value="调拨入库" />
                        <el-option label="调拨出库" value="调拨出库" />
                        <el-option label="领用出库" value="领用出库" />
                        <el-option label="领用返还入库" value="领用返还入库" />
                        <el-option label="验收入库" value="验收入库" />
                    </el-select>
                </el-form-item>
                <el-form-item label="派单时间" style="width: 300px;">
                    <el-date-picker v-model="pdDateArr" type="daterange" range-separator="—" start-placeholder="开始时间"
                        end-placeholder="结束时间" @change="dateChange" />
                </el-form-item>
                <el-row class="margin-style">
                    <el-col>
                        <el-button type="primary" @click="getPoAssetsList(currentPage)">点击查询</el-button>
                        <el-button @click="onRest">一键清空</el-button>
                        <el-button type="primary" @click="exportToExcels('资产PO单', tableData)">{{ exportBtnName }}</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>

        <div class="content-header">
            <div class="span-style" />
            <span>工单列表</span>
        </div>
        <el-table ref="multipleTableRef" :data="tableData" @selection-change="handleSelectionChange" row-key="id"
            @row-dblclick="handleRowDblclick">
            <el-table-column type="selection" width="30" />

            <el-table-column v-for="(column, index) in columns" :key="index" :prop="column.prop" :label="column.label"
                sortable>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="120">
                <template #default="scope">
                    <el-button link type="primary" size="small" @click.prevent="handleRowDblclick(scope.row)">
                        明细
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="currentPage"
            :page-sizes="[20,50,100]" :page-size="pageSize" layout="total, sizes,prev, pager, next, jumper" :total="totalData"></el-pagination>
    </div>
</template>

<script setup>
import { reactive, ref, onMounted, computed } from 'vue'
import { exportToExcel, objectToQueryString } from '../components/exportUtils.js'
import { get, post } from '../components/https'
import { useRouter } from 'vue-router'

// 加载状态
const loading = ref(false)
const router = useRouter()
const exportToExcels = (tableName, rows) => {
    // 显示全局遮罩
    loading.value = true
    let queryString
    if (multipleSelection.value.length > 0) {
        queryString = objectToQueryString({gdh: multipleSelection.value.map(obj => obj.gdh)})
    } else {
        queryString = objectToQueryString(formInline.value)
    }
    const url = 'https://www.aiyuservice.com/api/poAssets/export?' + queryString
    fetch(url, { method: 'GET', headers: { 'Satoken': localStorage.getItem('Satoken') } })
        .then(response => {
            // 关闭全局遮罩
            loading.value = false
            return response.blob()
        }).then(blob => { 
            const blobUrl = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = blobUrl
            link.setAttribute('download', tableName + '.xlsx')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            window.URL.revokeObjectURL(blobUrl)
        }).catch(error => {
            // 关闭全局遮罩
            loading.value = false
            console.error('下载失败：', error)
        })
}
const handleRowDblclick = (row) => {
    router.push({ name: 'AssetCountListDetail', params: { id: row.gdh } })
}

// 分页参数  
const currentPage = ref(1)
const pageSize = ref(20)
const totalData = ref(0)

// 当前页码改变时的处理  
function handleCurrentChange(val) {
    currentPage.value = val
    getPoAssetsList(currentPage.value)
}
function handleSizeChange(val) {
    pageSize.value = val
    getPoAssetsList(currentPage.value)
}
// 派单时间
const pdDateArr = ref([])
const formInline = ref({
    // 关键字
    gjz: '',
    // 工单号
    gdh: '',
    // 园区
    yq: '',
    // 工单类型
    gdlx: '',
    // 派单开始时间
    pdkssj: '',
    // 派单结束时间
    pdjssj: ''
})
const columns = [
    {
        prop: 'gdh',
        label: '工单号'
    },
    {
        prop: 'jfdym',
        label: '机房单元名'
    },
    {
        prop: 'gdbt',
        label: '工单标题'
    },
    {
        prop: 'gdlx',
        label: '工单类型'
    },
    {
        prop: 'slajzsj',
        label: 'sla截止时间'
    }
]

const multipleTableRef = ref([])
const multipleSelection = ref([])
const exportBtnName = ref('导出全部')
const handleSelectionChange = (val) => {
    multipleSelection.value = val
    if (val.length > 0) {
        exportBtnName.value = '导出选中'
    } else {
        exportBtnName.value = '导出全部'
    }
}

const tableData = ref([])
onMounted(() => {
    getPoAssetsList(currentPage.value)
})
const getPoAssetsList = (currentPage) => {
    // 显示全局遮罩
    loading.value = true;
    let queryString = objectToQueryString(formInline.value);
    get('poAssets/getInfo?currentPage=' + currentPage + '&' +'pageSize='+ pageSize.value +'&'+ queryString).then(data => {
        if (data.errorCode === 0) {
            tableData.value = data.result.poAssetsInfoList
            totalData.value = data.result.totalElements
        }
    }).finally(()=>{loading.value = false})
}
const dateChange = (value) => {
    if (value) {
        formInline.value.pdkssj = value[0]
        formInline.value.pdjssj = value[1]
    } else {
        formInline.value.pdkssj = ''
        formInline.value.pdjssj = ''
    }
}
const onRest = () => {
    pdDateArr.value = []
    formInline.value = {
        // 关键字
        gjz: '',
        // 工单号
        gdh: '',
        // 园区
        yq: '',
        // 工单类型
        gdlx: '',
        // 派单开始时间
        pdkssj: '',
        // 派单结束时间
        pdjssj: ''
    }
}
</script>

<style>
.form-inline .el-form-item {
    width: 250px;
}

.content-header {
    margin-bottom: 10px;
    font-weight: bold;
}

.span-style {
    display: inline-block;
    height: 20px;
    width: 3px;
    background: #409eff;
    vertical-align: bottom;
    margin-right: 10px;
}

.box-card {
    margin-bottom: 30px;
}

.margin-style {
    margin-top: 10px;
}

.item-content {
    height: 40px;
}

.el-table tr {
    cursor: pointer;
}
</style>
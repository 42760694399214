<template>
    <div>
        <div class="content-header">
            <div class="span-style" />
            <span>搜索条件</span>
        </div>
        <el-card class="box-card">
            <el-form :inline="true" :model="formInline" class="form-inline">
                <el-form-item label="关键字">
                    <el-input v-model="formInline.gjz"></el-input>
                </el-form-item>
                <el-form-item label="工单等级">
                    <el-select v-model="formInline.order_level" placeholder="请选择">
                        <el-option v-for="item in options" :key="item" :label="item" :value="item" />
                    </el-select>
                </el-form-item>
                <el-form-item label="创建时间" style="width: 300px;">
                    <el-date-picker v-model="dateArr" type="daterange" range-separator="—"
                    start-placeholder="开始时间" end-placeholder="结束时间" @change="dateChange" />
                </el-form-item>
                <el-row class="margin-style"><el-col>
                        <el-button @click="onRest">重置</el-button>
                        <el-button type="primary" @click="onSubmit">批量催单</el-button>
                        <el-button type="primary" @click="getTicketList(currentPage)">搜索</el-button>
                    </el-col></el-row>
            </el-form>
        </el-card>

        <div class="content-header">
            <div class="span-style" />
            <span>工单列表</span>
        </div>
        <el-button @click="exportToExcels('服务器升级工单列表',tableData)">导出</el-button>
        <el-table ref="multipleTableRef" :data="tableData" @selection-change="handleSelectionChange" row-key="id" @row-dblclick="handleRowDblclick">
            <el-table-column type="selection" width="30" />
            <el-table-column prop="ticket_id" label="工单号" sortable>
            </el-table-column>
            <el-table-column v-for="(column, index) in columns" :key="index" :prop="column.prop" :label="column.label"
                sortable />
            <el-table-column fixed="right" label="操作" width="120">
                <template #default="scope">
                    <el-button link type="primary" size="small" @click.prevent="handleRowDblclick(scope.row)">
                        明细
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="currentPage"
            :page-sizes="[20,50,100]" :page-size="pageSize" layout="total, sizes,prev, pager, next, jumper" :total="totalData"></el-pagination>
    </div>
</template>

<script setup>
import { ArrowDown,ArrowUp } from '@element-plus/icons-vue'
import { reactive, ref, onMounted, computed } from 'vue'
import { exportToExcel, objectToQueryString } from '../components/exportUtils.js';
import { get, post } from '../components/https';
import { useRouter } from 'vue-router';

const router = useRouter();
const exportToExcels = (tableName, rows) => {
    if (!dateArr.value) {
        delete formInline.value.startDate
        delete formInline.value.endDate
    }
    let queryString = objectToQueryString(formInline.value);
    const url = 'https://www.aiyuservice.com/api/technology/export?' + queryString
    fetch(url, { method: 'GET', headers: { 'Satoken': localStorage.getItem('Satoken') } })
        .then(response => {
            return response.blob();
        }).then(blob => { 
            const blobUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', tableName + '.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        }).catch(error => {
            console.error('下载失败：', error);
        });
}

// 分页参数  
const currentPage = ref(1);
const pageSize = ref(20);
const totalData = ref(0)

// 当前页码改变时的处理  
function handleCurrentChange(val) {
    currentPage.value = val;
    getTicketList(currentPage.value)
}
function handleSizeChange(val) {
    pageSize.value = val;
    getTicketList(currentPage.value)
}
const dateArr = ref('')
const formInline = ref({
    gjz: '',
    order_level: '',
})
const columns = [
{
        prop: 'asset_id',
        label: '服务器设备号'
    },
    {
        prop: 'order_level',
        label: '工单等级'
    },
    {
        prop: 'order_type',
        label: '工单类型'
    },
    {
        prop: 'chaungjian',
        label: '创建时间'
    },
    {
        prop: 'create_name',
        label: '创建人'
    },
]
const rowDetail = ref()

const multipleTableRef = ref()
const multipleSelection = ref([])
const handleSelectionChange = (val) => {
    multipleSelection.value = val
}

const tableData = ref([])
onMounted(() => {
    getTicketList(currentPage.value)
    getOptions()
})

const options=ref([])
const getOptions = () => {
    get('technology/info').then(data => {
        if (data.errorCode === 0) {
            options.value = data.result.order_levels
        }
    })
}
const truncateText = (text, length) =>{  
      if (text.length > length) {  
        return text.slice(0, length) + '...';  
      }  
      return text;  
    } 
const toggleFullContent =(index) => {  
    tableData.value[index].showFullContent = !tableData.value[index].showFullContent || false;  
    }
const getTicketList = (currentPage) => {
    if (!dateArr.value) {
        delete formInline.value.startDate
        delete formInline.value.endDate
    }
    let queryString = objectToQueryString(formInline.value); 
    get('technology/list?currentPage=' + currentPage + '&'+ 'pageSize='+ pageSize.value +'&'+ queryString).then(data => {
        if (data.errorCode === 0) {
            tableData.value = data.result.content
            totalData.value = data.result.totalElements
            tableData.value.forEach(item => {
                item.showFullContent = false;
            });
        }
        
    })
}
const dateChange = (value) => {
    formInline.value.startDate = value[0]
    formInline.value.endDate = value[1]
}
const onRest = () => {
    dateArr.value = ""
    formInline.value = {
        gjz: '',
        order_level: '',
    }
}
const handleRowDblclick = (row) => {
    router.push({ name: 'ServiceDetail', params: { id: row.ticket_id } });
}
</script>


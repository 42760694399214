<template>
    <div>
        <div class="change-card">
            <el-row class="card-row">
                <el-col :span="6" class="card-col" @click="orderType = 'gdlb'">
                    <div class="card-title">工单列表</div>
                    <span class="card-num">{{ gdlb }}</span>
                </el-col>
                <el-col :span="6" class="card-col" @click="orderType = 'csgd'">
                    <div class="card-title">超时工单</div>
                    <span class="card-num">{{ csgd }}</span>
                </el-col>
                <el-col :span="6" class="card-col" @click="orderType = 'cwgd'">
                    <div class="card-title">长尾工单</div>
                    <span class="card-num">{{ cwgd }}</span>
                </el-col>
                <el-col :span="6" class="card-col" @click="orderType = 'yjgd'">
                    <div class="card-title">预警工单</div>
                    <span class="card-num">{{ yjgd }}</span>
                </el-col>    
            </el-row>
        </div>
        <div class="content-header">
            <div class="span-style" />
            <span>搜索条件</span>
        </div>
        <el-card class="box-card">
            <el-form :inline="true" :model="formInline" class="form-inline">
                <el-form-item label="关键字">
                    <el-input v-model="formInline.pgjz"></el-input>
                </el-form-item>
                <el-form-item label="机房">
                    <!-- <el-input v-model="formInline.pjf"></el-input> -->
                    <el-autocomplete class="inline-input" v-model="formInline.pjf" :fetch-suggestions="querySearch"
                        placeholder="请输入内容" @select="handleSelect"></el-autocomplete>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="formInline.pzt" filterable allow-create default-first-option placeholder="请选择">
                        <el-option label="已完成" value="已完成" />
                        <el-option label="未完成" value="未完成" />
                    </el-select>
                </el-form-item>
                <el-form-item label="日期" style="width: 300px;">
                    <el-date-picker v-model="dateArr" type="daterange" range-separator="—" start-placeholder="开始时间"
                        end-placeholder="结束时间" @change="dateChange" />
                </el-form-item>
                <el-form-item label="工单类型">
                    <el-select v-model="formInline.pcjlx" placeholder="请选择" multiple>
                        <el-option label="服务器退役" value="服务器退役" />
                        <el-option label="服务器搬迁" value="服务器搬迁" />
                        <el-option label="网络故障" value="网络故障" />
                        <el-option label="TPC异常处理" value="TPC异常处理" />
                        <el-option label="网络工单" value="网络工单" />
                        <el-option label="机框出库" value="机框出库" />
                        <el-option label="服务器故障" value="服务器故障" />
                    </el-select>
                </el-form-item>
                <el-row class="margin-style"><el-col>
                        <el-button @click="onRest">重置</el-button>
                        <el-button type="primary" @click="onSubmit">批量催单</el-button>
                        <el-button type="primary" @click="getTicketList(currentPage)">搜索</el-button>
                    </el-col></el-row>
            </el-form>
        </el-card>

        <div class="content-header">
            <div class="span-style" />
            <span>工单列表</span>
        </div>
        <div v-if="orderType == 'csgd'">

            <el-button @click="exportToExcels('工单列表', tableData)">导出</el-button>
            <el-table ref="multipleTableRef" :data="tableData" @selection-change="handleSelectionChange" row-key="id"
                @row-dblclick="handleRowDblclick">
                <el-table-column type="selection" width="30" />
                <el-table-column prop="gdh" label="工单号" sortable>
                </el-table-column>
                <el-table-column prop="gdbt" label="工单标题" sortable>
                    <template #default="scope">
                        <div class="cell-content" @click="toggleFullContent(scope.$index)">
                            <span v-if="!scope.row.showFullContent">{{ truncateText(scope.row.gdbt, 10) }}</span>
                            <span v-else>{{ scope.row.gdbt }}</span>
                            <el-icon v-if="!scope.row.showFullContent">
                                <ArrowDown />
                            </el-icon>
                            <i class="el-icon-arrow-down" v-if="!scope.row.showFullContent"></i>
                            <el-icon v-else>
                                <ArrowUp />
                            </el-icon>
                            <i class="el-icon-arrow-up"></i>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column v-for="(column, index) in cscolumns" :key="index" :prop="column.prop"
                    :label="column.label" sortable />
                <el-table-column fixed="right" label="操作" width="120">
                    <template #default="scope">
                        <el-button link type="primary" size="small" @click.prevent="handleRowDblclick(scope.row)">
                            明细
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange"
                :current-page="currentPage" :page-sizes="[20, 50, 100]" :page-size="pageSize"
                layout="total, sizes,prev, pager, next, jumper" :total="totalData"></el-pagination>
        </div>
        <div v-if="orderType == 'cwgd'">

            <el-button @click="exportToExcels('长尾工单', tableData)">导出</el-button>
            <el-table ref="multipleTableRef" :data="tableData" @selection-change="handleSelectionChange" row-key="id"
                @row-dblclick="handleRowDblclick">
                <el-table-column type="selection" width="30" />
                <el-table-column prop="gdh" label="工单号" sortable>
                </el-table-column>
                <el-table-column prop="gdbt" label="工单标题" sortable>
                    <template #default="scope">
                        <div class="cell-content" @click="toggleFullContent(scope.$index)">
                            <span v-if="!scope.row.showFullContent">{{ truncateText(scope.row.gdbt, 10) }}</span>
                            <span v-else>{{ scope.row.gdbt }}</span>
                            <el-icon v-if="!scope.row.showFullContent">
                                <ArrowDown />
                            </el-icon>
                            <i class="el-icon-arrow-down" v-if="!scope.row.showFullContent"></i>
                            <el-icon v-else>
                                <ArrowUp />
                            </el-icon>
                            <i class="el-icon-arrow-up"></i>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column v-for="(column, index) in columns" :key="index" :prop="column.prop"
                    :label="column.label" sortable />
                <el-table-column fixed="right" label="操作" width="120">
                    <template #default="scope">
                        <el-button link type="primary" size="small" @click.prevent="handleRowDblclick(scope.row)">
                            明细
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange"
                :current-page="currentPage" :page-sizes="[20, 50, 100]" :page-size="pageSize"
                layout="total, sizes,prev, pager, next, jumper" :total="totalData"></el-pagination>
        </div>
        <div v-if="orderType == 'yjgd'">
            <el-button @click="exportToExcels('预警工单', tableData)">导出</el-button>
            <el-table ref="multipleTableRef" :data="tableData" @selection-change="handleSelectionChange" row-key="id"
                @row-dblclick="handleRowDblclick">
                <el-table-column type="selection" width="30" />
                <el-table-column prop="gdh" label="工单号" sortable>
                </el-table-column>
                <el-table-column prop="gdbt" label="工单标题" sortable>
                    <template #default="scope">
                        <div class="cell-content" @click="toggleFullContent(scope.$index)">
                            <span v-if="!scope.row.showFullContent">{{ truncateText(scope.row.gdbt, 10) }}</span>
                            <span v-else>{{ scope.row.gdbt }}</span>
                            <el-icon v-if="!scope.row.showFullContent">
                                <ArrowDown />
                            </el-icon>
                            <i class="el-icon-arrow-down" v-if="!scope.row.showFullContent"></i>
                            <el-icon v-else>
                                <ArrowUp />
                            </el-icon>
                            <i class="el-icon-arrow-up"></i>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column v-for="(column, index) in warnColumns" :key="index" :prop="column.prop"
                    :label="column.label" sortable />
                <el-table-column prop="yjmark" label="进度" sortable>
                    <template #default="scope">
                        <div class="cell-content">
                            <el-progress :text-inside="true" :stroke-width="15" :percentage="scope.row.yjmark"
                                status="warning" />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="120">
                    <template #default="scope">
                        <el-button link type="primary" size="small" @click.prevent="handleRowDblclick(scope.row)">
                            明细
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange"
                :current-page="currentPage" :page-sizes="[20, 50, 100]" :page-size="pageSize"
                layout="total, sizes,prev, pager, next, jumper" :total="totalData"></el-pagination>
        </div>
        <div v-if="orderType == 'gdlb'">
            <el-button @click="exportToExcels('工单列表', tableData)">导出</el-button>
            <el-table ref="multipleTableRef" :data="tableData" @selection-change="handleSelectionChange" row-key="id"
                @row-dblclick="handleRowDblclick">
                <el-table-column type="selection" width="30" />
                <el-table-column prop="gdh" label="工单号" sortable>
                </el-table-column>
                <el-table-column prop="gdbt" label="工单标题" sortable>
                    <template #default="scope">
                        <div class="cell-content" @click="toggleFullContent(scope.$index)">
                            <span v-if="!scope.row.showFullContent">{{ truncateText(scope.row.gdbt, 10) }}</span>
                            <span v-else>{{ scope.row.gdbt }}</span>
                            <el-icon v-if="!scope.row.showFullContent">
                                <ArrowDown />
                            </el-icon>
                            <i class="el-icon-arrow-down" v-if="!scope.row.showFullContent"></i>
                            <el-icon v-else>
                                <ArrowUp />
                            </el-icon>
                            <i class="el-icon-arrow-up"></i>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column v-for="(column, index) in orderColumns" :key="index" :prop="column.prop"
                    :label="column.label" sortable />
                <el-table-column fixed="right" label="操作" width="120">
                    <template #default="scope">
                        <el-button link type="primary" size="small" @click.prevent="handleRowDblclick(scope.row)">
                            明细
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange"
                :current-page="currentPage" :page-sizes="[20, 50, 100]" :page-size="pageSize"
                layout="total, sizes,prev, pager, next, jumper" :total="totalData"></el-pagination>
        </div>
    </div>
</template>

<script setup>
import { ArrowDown, ArrowUp } from '@element-plus/icons-vue'
import { reactive, ref, onMounted, computed, watch } from 'vue'
import { exportToExcel, objectToQueryString } from '../components/exportUtils.js';
import { get, post } from '../components/https';
import { useRouter } from 'vue-router';

const router = useRouter();
const orderType = ref('gdlb');
const exportToExcelUrl = ref('');

watch(orderType, (val) => {
    getTicketList(currentPage.value);
})
const exportToExcels = (tableName, rows) => {
    if (!dateArr.value) {
        delete formInline.value.startDate;
        delete formInline.value.endDate;
    }
    let queryString = objectToQueryString(formInline.value);
    switch (orderType.value) {
        case 'csgd':
            exportToExcelUrl.value = 'https://www.aiyuservice.com/api/ticket/cslistExport?' + queryString;
            break;
        case 'cwgd':
            exportToExcelUrl.value = 'https://www.aiyuservice.com/api/ticket/cwlistExport?' + queryString;
            break;
        case 'yjgd':
            exportToExcelUrl.value = 'https://www.aiyuservice.com/api/ticket/yjlistExport?' + queryString;
            break;
        case 'gdlb':
            exportToExcelUrl.value = 'https://www.aiyuservice.com/api/ticket/listExport?' + queryString;
            break;
    }
    const url = exportToExcelUrl.value;
    fetch(url, { method: 'GET', headers: { 'Satoken': localStorage.getItem('Satoken') } })
        .then(response => {
            return response.blob();
        }).then(blob => {
            const blobUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', tableName + '.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        }).catch(error => {
            console.error('下载失败：', error);
        });
}

// 分页参数  
const currentPage = ref(1);
const pageSize = ref(20);
const totalData = ref(0);

// 当前页码改变时的处理  
function handleCurrentChange(val) {
    currentPage.value = val;
    getTicketList(currentPage.value);
}
function handleSizeChange(val) {
    pageSize.value = val;
    getTicketList(currentPage.value);
}
const dateArr = ref('');
const formInline = ref({
    pgjz: '',
    pjf: '',
    pzt: '',
    pcjlx: []
});
const cscolumns = [
    {
        prop: 'gdlx',
        label: '工单类型'
    },
    {
        prop: 'dqzt',
        label: '当前状态'
    },
    {
        prop: 'gdzt',
        label: '工单状态'
    },
    {
        prop: 'yjjf',
        label: '一级机房'
    },
    {
        prop: 'dqsj',
        label: '到期时间'
    },
    {
        prop: 'jdsj',
        label: '结单时间'
    },
    {
        prop: 'csts',
        label: '超时天数'
    }
]
const columns = [
    // {
    //     prop: 'gdh',
    //     label: '工单号'
    // },
    // {
    //     prop: 'gdbt',
    //     label: '工单标题'
    // },
    {
        prop: 'gdlx',
        label: '工单类型'
    },
    {
        prop: 'dqzt',
        label: '当前状态'
    },
    {
        prop: 'gdzt',
        label: '工单状态'
    },
    {
        prop: 'yjjf',
        label: '一级机房'
    },
    {
        prop: 'dqsj',
        label: '到期时间'
    },
    {
        prop: 'jdsj',
        label: '结单时间'
    }
];
const warnColumns = [
    // {
    //     prop: 'gdh',
    //     label: '工单号'
    // },
    // {
    //     prop: 'gdbt',
    //     label: '工单标题'
    // },
    {
        prop: 'gdlx',
        label: '工单类型'
    },
    {
        prop: 'dqzt',
        label: '当前状态'
    },
    {
        prop: 'gdzt',
        label: '工单状态'
    },
    {
        prop: 'yjjf',
        label: '一级机房'
    },
    {
        prop: 'dqsj',
        label: '到期时间'
    },
    {
        prop: 'jdsj',
        label: '结单时间'
    }
]
const orderColumns = [
    // {
    //         prop: 'gdh',
    //         label: '工单号'
    //     },
    //     {
    //         prop: 'gdbt',
    //         label: '工单标题'
    //     },
    {
        prop: 'gdlx',
        label: '工单类型'
    },
    {
        prop: 'dqzt',
        label: '当前状态'
    },
    {
        prop: 'gdzt',
        label: '工单状态'
    },
    {
        prop: 'yjjf',
        label: '一级机房'
    },
    {
        prop: 'dqsj',
        label: '到期时间'
    },
    {
        prop: 'jdsj',
        label: '结单时间'
    }
]
const rowDetail = ref();
const csgd = ref(0), yjgd = ref(0), cwgd = ref(0), gdlb = ref(0);
const multipleTableRef = ref();
const multipleSelection = ref([]);
const handleSelectionChange = (val) => {
    multipleSelection.value = val;
};

const tableData = ref([]);
const restaurants = ref([]);
onMounted(() => {
    getTicketgdl();
    loadAll();
    getTicketList(currentPage.value);
});



const querySearch = (queryString, cb) => {
    // var restaurants = restaurants.value;
    var results = queryString ? restaurants.value.filter(createFilter(queryString)) : restaurants.value;
    // 调用 callback 返回建议列表的数据
    cb(results);
}
const createFilter = (queryString) => {
    return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
    };
}
const loadAll = () => {
    get('ticket/yjjfs').then(res => {
        if (res.errorCode === 0) {
            res.result.map(item => {
                restaurants.value.push({
                    value: item,
                    label: item
                });
            });
        }
    });
}
const getTicketgdl = () => {
    get('ticket/gdl').then(res => {
        if (res.errorCode === 0) {
            csgd.value = res.result.csgd;
            yjgd.value = res.result.yjgd;
            cwgd.value = res.result.cwgd;
            gdlb.value = res.result.gdlb;
        }
    });
}
const truncateText = (text, length) => {
    if (text.length > length) {
        return text.slice(0, length) + '...';
    }
    return text;
};
const toggleFullContent = (index) => {
    tableData.value[index].showFullContent = !tableData.value[index].showFullContent || false;
};
const getTicketList = (currentPage) => {
    if (!dateArr.value) {
        delete formInline.value.startDate;
        delete formInline.value.endDate;
    }
    let queryString = objectToQueryString(formInline.value);
    let apiUrl = '';
    switch (orderType.value) {
        case 'csgd':
            apiUrl = 'ticket/cslist';
            break;
        case 'cwgd':
            apiUrl = 'ticket/cwlist';
            break;
        case 'yjgd':
            apiUrl = 'ticket/yjlist';
            break;
        case 'gdlb':
            apiUrl = 'ticket/list';
            break;
        default:
            apiUrl = 'ticket/list';
    }
    get(apiUrl + '?currentPage=' + currentPage + '&' + 'pageSize=' + pageSize.value + '&' + queryString).then(data => {
        if (data.errorCode === 0) {
            tableData.value = data.result.content;
            totalData.value = data.result.totalElements;
            tableData.value.forEach(item => {
                item.showFullContent = false;
            });
            if(orderType.value === 'csgd') {
                tableData.value.forEach(item => {
                    const timeDay = new Date().getTime() - new Date(item.dqsj).getTime();
                    item.csts = Math.ceil(timeDay / (24 * 3600 * 1000));
                });
            }
        }
    });
};
const dateChange = (value) => {
    formInline.value.startDate = value[0];
    formInline.value.endDate = value[1];
};
const onRest = () => {
    dateArr.value = "";
    formInline.value = {
        pgjz: '',
        pjf: '',
        pzt: '',
        pcjlx: [],
    };
};
const handleRowDblclick = (row) => {
    router.push({ name: 'Detail', params: { id: row.gdh } });
};
</script>

<style scoped>
.change-card {
    margin: 10px 10px;
}

.card-col {
    text-align: center;
    padding: 10px 0;
    border: 1px solid #ccc;
    cursor: pointer;
    background: #409eff;
}

.card-title {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    margin-right: 10px;
}
</style>

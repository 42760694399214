<template>
    <div class="po-detail">
        <h2 disabled mode="h2" class="po-title">工单列表详情</h2>
        <div class="order-content">
            <div class="btn-group">
                <el-button type="primary" @click="editFlag = true" v-if="editFlag === false"
                    style="font-size:16px">编辑</el-button>
                <el-button type="primary" @click="editFlag = false, updateData()" v-if="editFlag === true"
                    style="font-size:16px">保存</el-button>
            </div>
            <el-form :inline="true" :model="poDetail" class="form-inline form-first margin-bt" label-position="left"
                label-width="120px">
                <el-form-item label="工单标题">
                    <span v-if="!editFlag">{{ poDetail.gdbt }}</span>
                    <el-input v-if="editFlag" v-model="poDetail.gdbt" style="width: 300px" />
                </el-form-item>
                <el-form-item label="工单类型">
                    <span>{{ poDetail.gdlx }}</span>
                    <!-- <el-input v-if="editFlag" v-model="poDetail.gdlx" style="width: 300px" /> -->
                </el-form-item>
                <el-form-item label="工单状态">
                    <span v-if="!editFlag">{{ poDetail.gdzt }}</span>
                    <el-input v-if="editFlag" v-model="poDetail.gdzt" style="width: 300px" />
                </el-form-item>
                <el-form-item label="一级机房">
                    <span v-if="!editFlag">{{ poDetail.yjjf }}</span>
                    <el-input v-if="editFlag" v-model="poDetail.yjjf" style="width: 300px" />
                </el-form-item>
                <el-form-item label="到期时间">
                    <span>{{ poDetail.dqsj }}</span>
                    <!-- <el-date-picker v-if="editFlag" v-model="poDetail.dqsj" type="datetime" placeholder="Select date and time" /> -->
                </el-form-item>
                <el-form-item label="结单时间">
                    <span v-if="!editFlag">{{ poDetail.jdsj }}</span>
                    <el-date-picker v-if="editFlag" v-model="poDetail.jdsj" type="datetime"
                        placeholder="选择结单时间" value-format="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss"/>
                </el-form-item>
                <el-form-item label="备注">
                    <span v-if="!editFlag">{{ poDetail.note }}</span>
                    <el-input type="textarea" v-if="editFlag" v-model="poDetail.note" style="width: 300px" />
                </el-form-item>
                <!-- 新增文件字段 -->
                <el-form-item label="文件" v-if="poDetail.wj" class="file-content">
                    <div v-if="!editFlag">
                        <!-- <span>{{ poDetail.wj.name }}</span>
                        <el-button type="primary" v-if="JSON.stringify(poDetail.wj) != '{}'" size="small"
                            @click="downloadFile(poDetail.wj.fileId)">下载文件</el-button> -->
                            <iframe
                                :src="'http://118.89.90.63/api/attach/display?url=' + poDetail.sp.fileId"
                                style="width: 100%; height: 800px; border: none;"
                                ></iframe>
                    </div>
                    <div v-if="editFlag">
                        <el-upload class="upload-attachment" action="#" :show-file-list="true"
                            :on-change="handleAttachmentChange" :file-list="createForm.wjs" :limit="1"
                            :on-exceed="handleAttachmentExceed" :on-remove="handleAttachmentRemove"
                            :before-upload="beforeAttachmentUpload" :auto-upload="false">
                            <el-button size="small" type="primary">点击上传附件</el-button>
                        </el-upload>
                    </div>
                </el-form-item>
                <!-- 新增视频字段 -->
                <el-form-item label="视频" v-if="poDetail.sp" class="file-content">
                    <div v-if="!editFlag">
                        <!-- <span>{{ poDetail.sp.name }}</span>
                        <el-button type="primary" size="small" v-if="JSON.stringify(poDetail.sp) != '{}'"
                            @click="downloadFile(poDetail.sp.fileId)">下载视频</el-button> -->
                        <video controls width="640" height="360">
                            <source :src="'http://118.89.90.63/api/attach/display?url=' + poDetail.sp.fileId" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div v-if="editFlag">
                        <el-upload class="upload-video" action="#" :show-file-list="true" :on-change="handleVideoChange"
                            :file-list="createForm.sps" :limit="1" :on-exceed="handleVideoExceed"
                            :on-remove="handleVideoRemove" :before-upload="beforeVideoUpload" :auto-upload="false"
                            accept="video/*">
                            <el-button size="small" type="primary">点击上传视频</el-button>
                        </el-upload>
                    </div>
                </el-form-item>
                <!-- 新增图片字段 -->
                <el-form-item label="图片" v-if="poDetail.tp" class="file-content">
                    <div v-if="!editFlag" class="image-content">
                        <!-- <span>{{ poDetail.tp.name }}</span>
                        <el-button type="primary" size="small" v-if="JSON.stringify(poDetail.tp) != '{}'"
                            @click="downloadFile(poDetail.tp.fileId)">下载图片</el-button> -->
                        <img :src="'http://118.89.90.63/api/attach/display?url=' + poDetail.tp.fileId" alt="Image" />
                    </div>
                    <div v-if="editFlag">
                        <el-upload class="upload-image" action="#" :show-file-list="true" :on-change="handleImageChange"
                            :file-list="createForm.tps" :limit="1" :on-exceed="handleImageExceed"
                            :on-remove="handleImageRemove" :before-upload="beforeImageUpload" :auto-upload="false"
                            accept="image/*">
                            <el-button size="small" type="primary">点击上传图片</el-button>
                        </el-upload>
                    </div>
                </el-form-item>
                <!-- <el-form-item></el-form-item>
                <el-form-item label="服务信息：">{{ poDetail.serviceInfo }}</el-form-item>
                <el-form-item label="专项(服务项)：">{{ poDetail.svrItem }}</el-form-item>
                <el-form-item label="待办工单号：">{{ poDetail.ticketId }}</el-form-item>
                <el-form-item label="腾讯备注信息：">{{ poDetail.memo }}</el-form-item>
                <el-form-item label="园区：">{{ poDetail.campusName }}</el-form-item>
                <el-form-item label="一级机房：">{{ poDetail.idcpName }}</el-form-item>
                <el-form-item label="机房管理单元：">{{ poDetail.idcName }}</el-form-item>
                <el-form-item label="待操作设备数量：">{{ poDetail.num }}</el-form-item>
                <el-form-item label="超时时间：">{{ poDetail.slaTime }}</el-form-item> -->
            </el-form>
            <!-- <div class="table-second margin-bt">
                <el-table :data="tableData" border style="width: 100%">
                    <el-table-column prop="assetId" label="固资" />
                    <el-table-column prop="sn" label="SN" />
                    <el-table-column prop="rackName" label="机架" />
                    <el-table-column prop="posName" label="机位" />
                    <el-table-column prop="model" label="机型" />
                    <el-table-column prop="svrType" label="设备类型" />
                </el-table>
            </div>
            <div class="form-three margin-bt-double">
                <el-form :model="poDetail" class="form-inline margin-bt" label-position="left">
                    <el-form-item label="是否接单:" prop="isAccepted" >
                        <el-radio-group v-model="poDetail.isAccepted" disabled>
                            <el-radio value="1">是</el-radio>
                            <el-radio value="0">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="分配工程师:" prop="engineerName" class="form-item-inline">
                        <el-input v-model="poDetail.engineerName" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="联系方式:" prop="engineerPhone" class="form-item-inline"
                        style="margin-left: 20px;">
                        <el-input v-model="poDetail.engineerPhone" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="备注:" prop="acceptedMemo" style="width: 100%">
                        <el-input type="textarea" disabled></el-input>
                    </el-form-item>
                </el-form>
            </div> -->
            <!-- <div class="table-four margin-bt">
                <h3 style="white-space: pre-wrap;">白名单</h3>
                <el-table :data="tableWhiteData" border style="width: 100%">
                    <el-table-column prop="name" label="姓名" />
                    <el-table-column prop="phone" label="联系方式" />
                    <el-table-column prop="IDNum" label="身份证号" />
                </el-table>
            </div> -->
        </div>
    </div>
</template>
<script setup>
import { useRouter, useRoute } from 'vue-router';
import { ref, onMounted } from 'vue'
import { get, post } from '../components/https';
import { ElMessage } from 'element-plus'

const router = useRouter();
const route = useRoute();
const poDetail = ref({})
const gdh = ref('')
const editFlag = ref(false)
const tableData = ref([]), tableWhiteData = ref([])

onMounted(() => {
    gdh.value = route.params.id
    getDataDetail(gdh.value)
})

const createForm = ref({
    wjs: [],
    tps: [],
    sps: []
});
const getDataDetail = (param) => {
    get('zjd/info?id=' + param).then(data => {
        console.log('拿到数据', data)
        if (data.errorCode == 0) {
            let dataJson = data.result
            poDetail.value = dataJson
            // tableData.value = JSON.parse(dataJson.deviceInfo)
            // tableWhiteData.value = JSON.parse(dataJson.whiteList)
        }
    })
}

const updateData = () => {
    post('zjd/update', poDetail.value).then(data => {
        console.log('拿到数据', data)
        if (data.errorCode == 0) {
            ElMessage.success('修改成功')
        }
    })
}
const downloadFile = (fileId) => {
    try {
        // 创建一个隐藏的 <a> 元素
        const link = document.createElement('a');
        link.href = 'https://www.aiyuservice.com/api/attach/download?url=' + fileId;
        link.download = fileId; // 使用 URL 的最后一部分作为文件名
        link.style.display = 'none';
        if (document.body) {
            document.body.appendChild(link);
            // 触发点击事件进行下载
            link.click();
            // 移除元素
            document.body.removeChild(link);
        } else {
            console.error('document.body 不存在，无法下载文件');
        }
    } catch (error) {
        console.error('下载文件时发生错误:', error);
    }
}

// 处理附件上传
const handleAttachmentChange = (file, fileList) => {
    createForm.value.wjs = fileList;
}
const handleAttachmentExceed = (files, fileList) => {
    if (Array.isArray(files)) {
        this.$message.warning(`只能上传一个附件，当前已上传 ${fileList.length} 个。`);
    }
}
const handleAttachmentRemove = (file, fileList) => {
    createForm.value.wjs = fileList;
}
const beforeAttachmentUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        this.$message.error('附件大小不能超过 2MB!');
    }
    return isLt2M;
}

// 处理图片上传
const handleImageChange = (file, fileList) => {
    createForm.value.tps = fileList;
}
const handleImageExceed = (files, fileList) => {
    if (Array.isArray(files)) {
        this.$message.warning(`只能上传一张图片，当前已上传 ${fileList.length} 张。`);
    }
}
const handleImageRemove = (file, fileList) => {
    createForm.value.tps = fileList;
}
const beforeImageUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        this.$message.error('图片大小不能超过 2MB!');
    }
    return isLt2M;
}

// 处理视频上传
const handleVideoChange = (file, fileList) => {
    createForm.value.sps = fileList;
}
const handleVideoExceed = (files, fileList) => {
    if (Array.isArray(files)) {
        this.$message.warning(`只能上传一个视频，当前已上传 ${fileList.length} 个。`);
    }
}
const handleVideoRemove = (file, fileList) => {
    createForm.value.sps = fileList;
}
const beforeVideoUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        this.$message.error('视频大小不能超过 2MB!');
    }
    return isLt2M;
}
</script>
<style scoped>
.btn-group {
    text-align: end;
}

.po-detail {
    margin: 0 20px;
}

.file-content {
    width: 100%;
}
.order-content .form-inline .el-form-item {
    width: 300px;
}

.po-title {
    padding: 0;
    margin: 20px 0;
    box-sizing: border-box;
    white-space: pre-wrap;
    border-bottom-color: rgb(74, 144, 226);
    border-style: solid;
    text-align: center;
    width: auto;

}

.form-first.el-form-item {
    width: 46%;
    margin-bottom: 25px;
}

/deep/.form-inline.el-form-item__label {
    color: #606266;
    font-weight: 700;
}

.margin-bt {
    margin-bottom: 22px;
}

.margin-bt-double {
    margin-bottom: 40px;
}

.table-second,
.table-four {
    border-bottom-color: rgb(74, 144, 226);
    border-bottom-style: solid;
}

.form-item-inline {
    display: inline-flex;
}
</style>
<template>
    <div class="work-management">
        <div class="content-header">
            <div class="span-style" />
            <span>差旅操作</span>
        </div>
        <el-card class="box-card">
            <el-button>差旅申请</el-button>
        </el-card>

        <div class="table-div">
            <div class="content-header">
                <div class="span-style" />
                <span>搜索条件</span>
            </div>
            <el-card class="box-card">
                <el-form :inline="true" :model="formInline" class="form-inline">
                    <el-form-item label="关键字">
                        <el-input v-model="formInline.pgjz"></el-input>
                    </el-form-item>
                    <el-form-item label="机房">
                        <el-input v-model="formInline.jf"></el-input>
                    </el-form-item>
                    <el-form-item label="申请日期">
                        <el-date-picker
                            v-model="formInline.sqrq"
                            type="date"
                        />
                    </el-form-item>
                    <el-row class="margin-style"><el-col>
                            <el-button @click="onRest">重置</el-button>
                            <el-button type="primary" >搜索</el-button> <!--@click="getTicketList(currentPage)"-->
                        </el-col></el-row>
                </el-form>
            </el-card>

            <div class="content-header">
                <div class="span-style" />
                <span>工单列表</span>
            </div>
            <el-button @click="exportToExcels('考勤管理', tableData)">导出</el-button>
        <el-table ref="multipleTableRef" :data="tableData" @selection-change="handleSelectionChange" row-key="id" @row-dblclick="handleRowDblclick">
            <el-table-column type="selection" width="30" />
            <el-table-column prop="sqr" label="申请人" sortable>
            </el-table-column>
            <el-table-column prop="sqrq" label="申请日期" sortable>
            </el-table-column>
            <el-table-column prop="lxfs" label="所属机房" sortable>
            </el-table-column>
            <el-table-column prop="gw" label="出差天数" sortable>
            </el-table-column>
            <el-table-column prop="cccc" label="出差城市" sortable>
            </el-table-column>
            <el-table-column prop="yjjf" label="出差原因" sortable>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="120">
                <template #default="scope">
                    <el-button link type="primary" size="small" @click.prevent="handleRowDblclick(scope.row)">
                        明细
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="currentPage"
            :page-sizes="[20,50,100]" :page-size="pageSize" layout="total, sizes,prev, pager, next, jumper" :total="totalData"></el-pagination>
        </div>
    </div>
</template>

<script setup>
import { reactive, ref, onMounted, computed } from 'vue'
import { exportToExcel, objectToQueryString } from '../../components/exportUtils.js';
import { get, post } from '../../components/https';
import { useRouter } from 'vue-router';

const router = useRouter();

const formInline = ref({
    pgjz: '',
})
const tableData = ref([
    {
        id: '1',
        sqr: '朱沈欣',
        sqrq: '2024/9/26',
        lxfs: '上海-金云桥',
        gw: '3',
        cccc: '昆山',
        yjjf: '交叉审计',
    },
    {
        id: '2',
        sqr: '于明起',
        sqrq: '2024/11/4',
        lxfs: '济南-春晖',
        gw: '2',
        cccc: '张家口',
        yjjf: '客户季度汇报',
    },
])
onMounted(() => {
    // getTicketList(currentPage.value)
})
const onRest = () => {
    formInline.value = {
        pgjz: ''
    }
}
const multipleTableRef = ref()
const multipleSelection = ref([])
const handleSelectionChange = (val) => {
    multipleSelection.value = val
}
// 分页参数  
const currentPage = ref(1);
const pageSize = ref(20);
const totalData = ref(0)

// 当前页码改变时的处理  
function handleCurrentChange(val) {
    currentPage.value = val;
    // getTicketList(currentPage.value)
}
// 当分页数据改变时
function handleSizeChange(val) {
    pageSize.value = val;
    // getTicketList(currentPage.value)
}
const getTicketList = (currentPage) => {
    let queryString = objectToQueryString(formInline.value);
    get('ticket1111/cwlist?currentPage=' + currentPage + '&' +'pageSize='+ pageSize.value +'&'+ queryString).then(data => {
        if (data.errorCode === 0) {
            tableData.value = data.result.content
            totalData.value = data.result.totalElements
            tableData.value.forEach(item => {
                item.showFullContent = false;
            });
        }

    })
}
// 导出表格数据
const exportToExcels = (tableName, rows) => {
    if (!dateArr.value) {
        delete formInline.value.startDate
        delete formInline.value.endDate
    }
    let queryString = objectToQueryString(formInline.value);
    const url = 'https://www.aiyuservice.com/api/ticket/cwlistExport?' + queryString
    fetch(url, { method: 'GET', headers: { 'Satoken': localStorage.getItem('Satoken') } })
        .then(response => {
            return response.blob();
        }).then(blob => {            
            const blobUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', tableName + '.xlsx');
            document.body.appendChild(link);
            link.click(); 
            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        }).catch(error => {
            console.error('下载失败：', error);
        });
}

const handleRowDblclick = (row) => {
    router.push({ name: 'Detail', params: { id: row.gdh } });
}
</script>

<style scoped>
.el-col {
    margin-bottom: 20px;
}
.statis-card {
    background: #F5F9FF;
}
</style>
<template>
    <el-menu :default-active="activeIndex" @select="handleSelect" mode="vertical" router background-color="#f5f7fa"
        class="sidebar">
        <el-sub-menu index="1" @click="changSelect">
            <template #title>
                <span style="font-weight: bold;">工单管理</span>
            </template>
            <el-menu-item index="/Overtimeorder">超时工单</el-menu-item>
            <el-menu-item index="/Longtailorder">长尾工单</el-menu-item>
            <el-menu-item index="/Earlywarnorder">预警工单</el-menu-item>
            <!--<el-menu-item index="/Oneselfbuildorder">联想自建工单</el-menu-item>-->
            <el-menu-item index="/Orderlist">工单列表</el-menu-item>
            <el-menu-item index="/OrderLists">工单列表新</el-menu-item>
            <el-menu-item index="/NetWorkorder">网络催办工单</el-menu-item>
            <el-menu-item index="/Serviceorder">服务器升级工单</el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="4">
            <template #title>
                <span style="font-weight: bold;">PO管理</span>
            </template>
            <el-menu-item index="/OrderPOList">服务器网络PO单列表</el-menu-item>
            <el-menu-item index="/CountList">服务器网络计费单列表</el-menu-item>
            <el-menu-item index="/AssetCountList">资产PO单列表</el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="2">
            <template #title>
                <span style="font-weight: bold;">IT集中化运营管理</span>
            </template>
            <el-sub-menu index="3">
                <template #title>
                    <span style="font-weight: bold;">服务集中运营平台</span>
                </template>
                <el-menu-item index="/OperaDashboard">服务器退役和搬迁</el-menu-item>
                <el-menu-item index="/TPCDashboard">TPC异常处理</el-menu-item>
                <el-menu-item index="/PODashboard">PO看板</el-menu-item>
            </el-sub-menu>
            <!-- <el-menu-item index="/ChatPage">服务集中运营平台</el-menu-item> -->
            <el-menu-item index="/ComputerRoom">资源池白名单人员情况</el-menu-item>
            <el-menu-item index="/UserRoom">驻场人员情况</el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="5">
            <template #title>
                <span style="font-weight: bold;">驻场人员管理</span>
            </template>
            <el-sub-menu index="51">
                <template #title>
                    <span style="font-weight: bold;">考勤休假管理</span>
                </template>
                <el-menu-item index="/WorkManagement">考勤管理</el-menu-item>
                <el-menu-item index="/SchedulingManagement">排班管理</el-menu-item>
                <el-menu-item index="/TravelManagement">差旅管理</el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="52">
                <template #title>
                    <span style="font-weight: bold;">人员标签/培训考试管理</span>
                </template>
                <el-menu-item index="/PersonTag">人员标签管理</el-menu-item>
                <el-menu-item index="/Training">培训管理</el-menu-item>
                <el-menu-item index="/Examination">考试管理</el-menu-item>
            </el-sub-menu>
            <el-menu-item index="/Performance">绩效考核管理</el-menu-item>
        </el-sub-menu>
        <el-menu-item index="/SelfBuildOrder">自建单管理</el-menu-item>
    </el-menu>
</template>

<script setup>
import { ref, watch, onMounted, onUnmounted, defineEmits } from 'vue'
import { useRouter } from 'vue-router';
import { get } from '../components/https';

const loginFlag = ref(false)
const storedActiveIndex = localStorage.getItem('activeIndex') == "/" ? '/Overtimeorder' : localStorage.getItem('activeIndex')
const activeIndex = ref(storedActiveIndex)
const router = useRouter();
const emit = defineEmits(['breadcrumb'])
const judgeMenu = (e) => {
    let authToken = localStorage.getItem('AuthToken')
    let userId = localStorage.getItem('UserId')
    // 测试去掉
    if (!authToken || !userId) {
        router.push("/Whitepage");
    } else if (!loginFlag.value) {
        get('user/login?authToken=' + authToken + '&userId=' + userId).then(data => {
            loginFlag.value = true
            if (data.errorCode != 0) {
                router.push("/Whitepage");
                loginFlag.value = false
            } else {
                let satoken = data.result
                if (satoken) {
                    localStorage.setItem('Satoken', satoken)
                }
            }
        })
    }
}
onMounted(() => {
    // 测试去掉
    judgeMenu(activeIndex.value)
    router.push(activeIndex.value)
    emit('update:breadcrumb', activeIndex.value)
});

const changSelect = (e) => {
    // 测试时去掉校验
    let authToken = localStorage.getItem('AuthToken')
    let userId = localStorage.getItem('UserId')
    if (!authToken || !userId) {
        router.push("/Whitepage");
    } else if (!loginFlag.value) {
        get('user/login?authToken=' + authToken + '&userId=' + userId).then(data => {
            loginFlag.value = true
            if (data.errorCode != 0) {
                router.push("/Whitepage");
                loginFlag.value = false
            } else {
                let satoken = data.result
                console.log("check satoken", satoken)
                if (satoken) {
                    localStorage.setItem('Satoken', satoken)
                }
            }
        })
    }

}

const handleSelect = (key) => {
    router.push(key)
    activeIndex.value = key
    emit('update:breadcrumb', key)
}

</script>

<style scoped>
.el-menu-item.is-active {
    background-color: #D8DAE1;
    /* 设置选中菜单项的背景颜色 */
}
</style>
<template>
    <div>
        <div class="content-header">
            <div class="span-style" />
            <span>工单详情</span>
            <el-button @click="goBack" :icon="ArrowLeft" type="primary" class="operBtn">返回</el-button>
            <el-button @click="goEdit" :icon="Edit" class="operBtn" v-if="!editFlag"
                style="margin-right: 10px;">编辑</el-button>
            <el-button @click="goSave" :icon="Edit" class="operBtn" v-if="editFlag"
                style="margin-right: 10px;">保存</el-button>
        </div>
        <!-- <h2 class="detail-header">{{ gdh }}详情页</h2> -->
        <!-- 展示详情数据... -->

        <div class="dataDetail">
            <div class="item" v-for="item in transformedArray" :key="item.label">
                <span class="itemLabel">{{ item.label }}：</span>
                <span class="itemValue">{{ item.value }}</span>
            </div>
            <div class="item" v-if="!editFlag">
                <span class="itemLabel">是否剔除超时：</span>
                <span class="itemValue">{{ editArr.sftccs }}</span>
            </div>
            <div class="item" v-if="editFlag">
                <span class="itemLabel">是否剔除超时：</span>
                <span class="itemValue">
                    <el-radio-group v-model="editArr.sftccs" class="ml-4">
                        <el-radio value="是">是</el-radio>
                        <el-radio value="否">否</el-radio>
                    </el-radio-group>
                </span>
            </div>
            <div class="item" v-if="!editFlag">
                <span class="itemLabel">超时原因：</span>
                <span class="itemValue">{{ editArr.csyy }}</span>
            </div>
            <div class="item" v-if="editFlag">
                <span class="itemLabel">超时原因：</span>
                <span class="itemValue">
                    <el-input v-model="editArr.csyy" maxlength="2000" placeholder="请输入" rows="4"
                        show-word-limit style="margin-bottom: 20px;" type="textarea" />
                </span>
            </div>
        </div>
    </div>
</template>

<script setup>
import {
    ArrowLeft
} from '@element-plus/icons-vue'
import { useRouter, useRoute } from 'vue-router';
import { ref, onMounted } from 'vue'
import { get, post } from '../components/https';

const router = useRouter();
const route = useRoute();
const editArr = ref({ gdh: '',sftccs: '', csyy: '' });
const dataLabel = {
    gdh: '工单号',
    gdbt: '工单标题',
    gdlx: '工单类型',
    dqzt: '当前状态',
    gdzt: '工单状态',
    yjjf: '一级机房',
    dqsj: '到期时间',
    jdsj: '结单时间',
    amount: '总台数',
    processUsers: '当前处理人',
    campus: '园区',
    idcName: '机房单元',
    requestId: '上游实例id',
    supplierContactName: '服务商联系人',
    supplierName: '服务商名称',
    udTime: '上/下架完成时间',
    scene: '场景',
    arriveTicketTime: '到达机房时间',
    assignTime: '上游派单时间',
    assigner: '上游派单人',
    completer: '结单人',
    completerPhone: '结单人电话',
    counts: '维修数量',
    expectOperateTime: '期望驻场操作时间',
    finishTicketTime: '驻场操作完成时间',
    handler: '派单时联系人',
    handlerPhone: '派单时联系人电话',
    isDangerOps: '是否高危操作',
    operationTicketTime: '开始操作时间',
    receiveTicketTime: '工单响应时间',
    receiveTicketId: '关联收件单号',
    repairType: '维修类型',
    replaceType: '替换类型',
    ticketNo: '上游单号',
    xjjg: '巡检结果',
    cjlx: '场景类型',
    sjdh: '审计单号',
    xjdh: '巡检单号',
    cbddh: '催办单单号',
    zchf: '驻场回复',
    sfcs: '是否超时',
    sfxjjpd: '是否巡检派单',
    gdsfgq: '工单是否挂起',
    fjdfzc: '复检是否正常',
    sfzysq: '是否资源申请',
    sfcb: '是否催办',
    gdgqsj: '工单挂起时间',
    gdgqtp: '工单挂起图片',
    zysqdh: '资源申请单号',
    cbhf: '催办回复'
}
const transformedArray = ref()
const gdh = ref('')
const goBack = () => {
    router.back();
};
const editFlag = ref(false)
const goEdit = () => {
    editFlag.value = true
}
const goSave = () => {
    post('ticket/updatCS', editArr.value).then(data => {
        console.log('拿到数据', data)
        if (data.errorCode == 0) {
            editFlag.value = false
        }
    })
}
onMounted(() => {
    gdh.value = route.params.id
    getDataDetail(gdh.value)
})
const getDataDetail = (param) => {
    get('ticket/info?gdh=' + param).then(data => {
        console.log('拿到数据', data)
        if (data.errorCode == 0) {
            let dataJson = data.result
            transformedArray.value = transformObjectsToArray(dataLabel, dataJson)
            editArr.value = { gdh: dataJson.gdh,sftccs: dataJson.sftccs, csyy: dataJson.csyy }
        }
    })
}

const transformObjectsToArray = (keysObject, dataObject) => {
    const result = [];

    // 遍历键的集合对象，获取所有的键  
    for (const key in keysObject) {
        // 如果数据对象中也有这个键，并且它的值不是null或undefined  
        if (key in dataObject) {
            // 将键值对转换为{label: key, value: dataObject[key]}格式的对象，并添加到结果数组中  
            result.push({ label: keysObject[key], value: dataObject[key] });
        }
    }

    return result;
}  
</script>
<style scoped>
.detail-header {
    text-align: center;
}

.item {
    height: 45px;
    font-size: 16px;
    display: inline-block;
    width: 32%;
    vertical-align: bottom;
    padding: 5px 5px;
}

.dataDetail {
    padding: 20px;
}

.operBtn {
    float: right;
    font-size: 14px;
}
</style>
<template>
  <div id="app" ref="resizeElement">
    <div class="sidebar" v-if="showSidebar">
      <Sidebar @update:breadcrumb="handleBreadcrumbUpdate" />
    </div>
    <div class="content">
      <div class="header-breadcrumb" v-if="showSidebar">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item v-for="(path, index) in currentPaths" :key="index" :to="path">{{ path
            }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <router-view />
      <div class="app-footer">
        <a href="https://beian.miit.gov.cn/" target="_blank">ICP备案：沪ICP备2024096570号-1</a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted, onUnmounted, watch, nextTick } from 'vue'
import Sidebar from './components/Sidebar.vue'
import { debounce } from "lodash";
import { useRouter, useRoute } from 'vue-router';

const resizeElement = ref(null); // 用于绑定 DOM 元素的 ref  
const router = useRouter();
const route = useRoute();
const showSidebar = ref(true)

// 页面加载时，从localStorage读取最后访问的路径，并尝试导航到该路径  
onMounted(async () => {
  const activeIndex = localStorage.getItem('activeIndex');
  if (activeIndex && activeIndex !== route.path) {
    await nextTick(); // 确保DOM更新完成  
    router.replace(activeIndex); // 使用replace避免在历史记录中留下当前路径  
  }
});

// 防抖的回调函数  
const debouncedResizeHandler = debounce(entries => {
  // 处理 resize 事件  
  console.log('Resized:', entries);
}, 100);

// 自定义的 ResizeObserver  
let resizeObserverInstance = null;

const createResizeObserver = () => {
  if (!resizeObserverInstance) {
    resizeObserverInstance = new ResizeObserver(debouncedResizeHandler);
    resizeObserverInstance.observe(resizeElement.value);
  }
};

const destroyResizeObserver = () => {
  if (resizeObserverInstance) {
    resizeObserverInstance.disconnect();
    resizeObserverInstance = null;
  }
};

onMounted(createResizeObserver);
onUnmounted(destroyResizeObserver);
onUnmounted(() => {
  localStorage.setItem('activeIndex', "/");
})
// '工单管理', 'PO管理','IT集中化运营管理'
const currentPaths = ref(['工单管理','PO管理'])
const pathArr = [
  { name: '超时工单', path: '/Overtimeorder' },
  { name: '长尾工单', path: '/Longtailorder' },
  { name: '预警工单', path: '/Earlywarnorder' },
  { name: '联想自建工单', path: '/Oneselfbuildorder' },
  { name: '工单列表', path: '/OrderLists' },
  { name: '工单列表', path: '/OrderPOList' },
  { name: '服务集中运营平台', path: '/ChatPage' },
  { name: '服务集中运营平台', path: '/OperaDashboard' },
  { name: '机房人力负载工作台', path: '/ComputerRoom' },
  { name: '详情', path: '/detail' },
  { name: '自建单管理', path: '/SelfBuildOrder' },
  { name: '资产PO单列表', path: '/AssetCountList' }
]
const handleBreadcrumbUpdate = (path) => {
  let dataItem = pathArr.filter(item => { return path.indexOf(item.path) > -1 })
  if (dataItem.length > 0) {
    currentPaths.value[1] = dataItem[0].name
  }
};

watch(
  () => route.path,
  (newPath) => {
    // 测试时去掉检验
    if (newPath == '/Whitepage') {
      showSidebar.value = false
    } else {
      showSidebar.value = true
    }
  },
  { immediate: true }
); 

</script>

<style>
.app-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
}

body {
  margin: 0;
}

#app {
  display: flex;
  height: 100vh;
  overflow: hidden;
  width: 100%
}

.sidebar {
  flex: 0 0 200px;
  background-color: #f8f9fa;
  overflow-y: auto;
  height: 100%;
}

.content {
  padding: 20px;
  flex: 1;
  overflow-y: auto;
  height: 95%;
}

.header-breadcrumb {
  line-height: 50px;
  height: 50px;
}

.el-breadcrumb {
  font-size: 17px;
}

.el-pagination {
  margin-top: 10px;
  margin-bottom: 20px;
}
</style>
<template>
    <div class="computer-room">
        <div class="computer-room-content">
            <el-row :gutter="20">
                <el-col :span="8">
                    <el-card class="statis-card">
                        <el-statistic title="服务器驻场数量" :value="spanData.userCount" class="statis-div"/>
                    </el-card>
                </el-col>
                <el-col :span="8">
                    <el-card  class="statis-card">
                        <el-statistic title="资产驻场数量" :value="spanData.resourceNum"  class="statis-div"/>
                    </el-card>
                </el-col>
                <el-col :span="8">
                    <el-card  class="statis-card">
                        <el-statistic title="网络驻场数量" :value="spanData.networkNum"  class="statis-div"/>
                    </el-card>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="8">
                    <el-card>
                        <div ref="chart1" class="chartDiv"></div>
                    </el-card>
                </el-col>
                <el-col :span="8">
                    <el-card>
                        <div ref="chart2" class="chartDiv"></div>
                    </el-card>
                </el-col>
                <el-col :span="8">
                    <el-card>
                        <div ref="chart3" class="chartDiv"></div>
                    </el-card>
                </el-col>
            </el-row>
        </div>
        <div>
            
            <el-card class="box-card">
                <el-form :inline="true" :model="formInline" class="form-inline">
                    <el-form-item label="关键字">
                        <el-input v-model="formInline.gjz"></el-input>
                    </el-form-item>
                    <el-row class="margin-style"><el-col>
                            <el-button @click="(formInline.gjz = '', getTicketList(currentPage))">重置</el-button>
                            <el-button type="primary" @click="getTicketList(currentPage)">搜索</el-button>
                            <el-button @click="handleAdd">新增白名单</el-button>
                            <el-button @click="exportToExcels('工单', tableData)">导出</el-button>
                        </el-col></el-row>
                </el-form>

                <el-table ref="multipleTableRef" :data="tableData" row-key="id" class="tableStyl">
                    <el-table-column v-for="(column, index) in columns" :key="index" :prop="column.prop"
                        :label="column.label" sortable />
                    <el-table-column fixed="right" label="操作" width="120">
                        <template #default="scope">
                            <el-button link type="primary" size="small" @click="handleDelete(scope.$index, scope.row)">
                                删除
                            </el-button>
                            <el-button link type="primary" size="small" @click="handleEdit(scope.$index, scope.row)">
                                编辑
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="currentPage"
            :page-sizes="[20,50,100]" :page-size="pageSize" layout="total, sizes,prev, pager, next, jumper" :total="totalData"></el-pagination>
            </el-card>
        </div>

        <el-drawer v-model="dialog" :title="drawerTitle" class="demo-drawer">
            <div class="demo-drawer__content">
                <el-form :model="form">
                    <el-form-item label="手机号码" prop="phone">
                        <el-input v-model="form.phone"></el-input>
                    </el-form-item>
                    <el-form-item label="员工姓名" prop="name">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>
                    <el-form-item label="岗位名称" prop="station">
                        <el-input v-model="form.station">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="园区" prop="park">
                        <el-input v-model="form.park"></el-input>
                    </el-form-item>
                    <el-form-item label="一级机房" prop="room">
                        <el-input v-model="form.room"></el-input>
                    </el-form-item>
                    <el-form-item label="所属主管" prop="director">
                        <el-input v-model="form.director">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="区域主管" prop="qudirector">
                        <el-input v-model="form.qudirector"></el-input>
                    </el-form-item>
                    <el-form-item label="机房类型" prop="room_type">
                        <el-input v-model="form.room_type"></el-input>
                    </el-form-item>
                    <el-form-item label="是否结费" prop="jiefei">
                        <el-radio-group v-model="form.jiefei">
                            <el-radio value="是">是</el-radio>
                            <el-radio value="否">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="所属主管电话" prop="zgphone">
                        <el-input v-model="form.zgphone"></el-input>
                    </el-form-item>
                    <el-form-item label="人员类型" prop="personnel_type">
                        <el-input v-model="form.personnel_type"></el-input>
                    </el-form-item>
                    <el-form-item label="城市" prop="city">
                        <el-input v-model="form.city"></el-input>
                    </el-form-item>
                    <el-form-item label="区域" prop="region">
                        <el-input v-model="form.region"></el-input>
                    </el-form-item>
                    <el-form-item label="工单类型" prop="wo_type">
                        <el-input v-model="form.wo_type"></el-input>
                    </el-form-item>
                    <el-form-item label="状态" prop="userStatus">
                        <el-input v-model="form.userStatus"></el-input>
                    </el-form-item>
                    <el-form-item label="班次" prop="banci">
                        <el-input v-model="form.banci"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="handleSubmit">提交</el-button>
                        <el-button @click="dialog = false">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-drawer>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import * as echarts from 'echarts'
import { get, post } from '../components/https';
import {  objectToQueryString } from '../components/exportUtils.js';

const drawerTitle = ref('')
const dialog = ref(false)
const form = ref({})
const spanData = ref({
    userCount: 0,
    resourceNum: 0,
    networkNum: 0
})
const tableData = ref([])
const chart1 = ref(null), chart2 = ref(null), chart3 = ref(null)
const columns = [
    {
        prop: 'name',
        label: '员工姓名'
    },
    {
        prop: 'phone',
        label: '手机号码'
    },
    {
        prop: 'station',
        label: '岗位名称'
    },
    {
        prop: 'room',
        label: '一级机房'
    },
    {
        prop: 'director',
        label: '区域主管'
    },
    {
        prop: 'room_type',
        label: '机房类型'
    },
    {
        prop: 'jiefei',
        label: '是否结费'
    }
]
const formInline = ref({
    gjz: ''
})
onMounted(() => {
    columns.map(item => {
        form.value[item.prop] = ''
    })
    const chartData1 = [], chartData2 = [], chartData3 = []
    get('user/data').then((res) => {
        if (res.errorCode == 0) {
            res.result['服务器驻场'].map(item => {
                spanData.value.userCount += item.yasix
                chartData1.push({ name: item.xasix, value: item.yasix })
            })
            res.result['资产驻场'].map(item => {
                spanData.value.resourceNum += item.yasix
                chartData2.push({ name: item.xasix, value: item.yasix })
            })
            res.result['网络驻场'].map(item => {
                spanData.value.networkNum += item.yasix
                chartData3.push({ name: item.xasix, value: item.yasix })
            })
            // 加载数据
            loadingChat1(chartData1)
            loadingChat2(chartData2)
            loadingChat3(chartData3)
        }
    })
    getTicketList(currentPage.value)
})
const exportToExcels = (tableName, rows) => {
    let queryString = objectToQueryString(formInline.value);
    const url = 'https://www.aiyuservice.com/api/user/export?' + queryString
    fetch(url, { method: 'GET', headers: { 'Satoken': localStorage.getItem('Satoken') } })
        .then(response => {
            return response.blob();
        }).then(blob => {
            const blobUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', tableName + '.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        }).catch(error => {
            console.error('下载失败：', error);
        });
}
const getTicketList = (currentPage) => {

    get('user/list?currentPage=' + currentPage + '&' + 'pageSize='+ pageSize.value +'&gjz=' + formInline.value.gjz).then((res) => {
        if (res.errorCode == 0) {
            tableData.value = res.result.content
            totalData.value = res.result.totalElements

        }
    })
}

const loadingChat1 = (data) => {
    let chart2 = data
    if (chart1.value) {
        let myChart = echarts.init(chart1.value);
        let option = {
            title: {
                text: '服务器驻场分布情况'
            },
            tooltip: {
                trigger: 'item'
            },
            legend: { bottom: 5 },
            label: {
                formatter: function (data) {
                    return data.name + ': ' + data.value
                }
            },
            grid: {
                containLabel: true
            },
            series: [
                {
                    name: '',
                    data: chart2,
                    type: 'pie',
                    radius: ['40%', '70%'],
                }
            ]
        };

        option && myChart.setOption(option);
    }
}

const loadingChat2 = (data) => {
    let chart1 = data
    if (chart2.value) {
        let myChart = echarts.init(chart2.value);
        let option = {
            title: {
                text: '资产驻场分布情况'
            },
            tooltip: {
                trigger: 'item'
            },
            legend: { bottom: 5 },
            label: {
                formatter: function (data) {
                    return data.name + ': ' + data.value
                }
            },
            grid: {
                containLabel: true
            },
            series: [
                {
                    name: '',
                    data: chart1,
                    type: 'pie',
                    radius: ['40%', '70%'],
                }
            ]
        };

        option && myChart.setOption(option);
    }
}

const loadingChat3 = (data) => {
    let chart2 = data
    if (chart3.value) {
        let myChart = echarts.init(chart3.value);
        let option = {
            title: {
                text: '网络驻场分布情况'
            },
            tooltip: {
                trigger: 'item'
            },
            legend: { bottom: 5 },
            label: {
                formatter: function (data) {
                    return data.name + ': ' + data.value
                }
            },
            grid: {
                containLabel: true
            },
            series: [
                {
                    name: '',
                    data: chart2,
                    type: 'pie',
                    radius: ['40%', '70%'],
                }
            ]
        };

        option && myChart.setOption(option);
    }
}

// 分页参数  
const currentPage = ref(1);
const pageSize = ref(20);
const totalData = ref(0)

// 当前页码改变时的处理  
function handleCurrentChange(val) {
    currentPage.value = val;
    getTicketList(currentPage.value)
}
function handleSizeChange(val) {
    pageSize.value = val;
    getTicketList(currentPage.value)
}
const handleDelete = (index, row) => {
    get('user/del?id=' + row.id).then((res) => {
        if (res.errorCode == 0) {
            tableData.value.splice(index, 1); // 从数组中移除该项
        }
    })
}

const handleEdit = (index, row) => {
    dialog.value = true
    form.value = { ...row }
    drawerTitle.value = '编辑'
}

const handleAdd = () => {
    dialog.value = true
    drawerTitle.value = '新增'
    form.value = {
        idcard: '',
        name: '',
        region: '',
        manager: '',
        locked: '',
        subquotient: '',
        comment: '',
        zaizhi: '',
    }
}

const handleSubmit = () => {
    if (drawerTitle.value == '新增') {
        post('user/create', form.value).then((res) => {
            if (res.errorCode == 0) {
                getTicketList(currentPage.value)
                dialog.value = false
            }
        })
    } else {
        post('user/update', form.value).then((res) => {
            if (res.errorCode == 0) {
                getTicketList(currentPage.value)
                dialog.value = false
            }
        })
    }
}
</script>

<style scoped>
.chartDiv {
    background-color: var(--el-bg-color-overlay);
    display: inline-block;
    border-radius: 10px;
    width: 100%;
    height: 320px;
    padding: 10px;
    margin: 0px 8px 8px 8px;
}

.tableStyl {
    margin-top: 20px;
}
.el-col {
    margin-bottom: 20px;
}
.statis-card {
    background: #F5F9FF;
}
>>>.statis-div .el-statistic__head {
    color: #2577DA;
}
>>>.statis-div .el-statistic__content {
    color: #2577DA;
    font-size: 30px;
    margin-left: 50px;
    font-weight: 800;
}
</style>